import React, { useEffect, useState } from 'react';
import { useShop } from './providers/ShopProvider';
import {TicketShop} from './Ticketshop';
import { EventProvider } from './providers/EventProvider';
import { Event } from '@/lib/TicketApi';

interface EventContainerProps {
  eventSlug: string;
}

export const EventContainer: React.FC<EventContainerProps> = ({eventSlug }) => {
  const [event, setEvent] = useState<Event | null>(null);
  const { shop } = useShop();

  useEffect(() => {
    const foundEvent = shop.events.find((e) => e.slug === eventSlug);
    if (foundEvent) {
      setEvent(foundEvent);
    }
  }, [eventSlug, shop.events]);
  
  return (
      <EventProvider event={event}>
        <TicketShop/>
      </EventProvider>
  );
};