'use client';

import { ShopStyleVariables } from '@/lib/shopStyles';
import { ShopStyle } from '@/lib/TicketApi';
import React, { useMemo } from 'react';

interface StyleOverwritesProps {
    children: JSX.Element | React.ReactNode;
    shopStyle?: ShopStyle;
}

const Default: ShopStyle = {
    panelBackgroundColor: '#ffffff',
    textColor: '#000000',
    removeFromCartButtonColor: '#000000',
    removeFromCartButtonTextColor: '#ffffff',
    addToCartButtonColor: '#000000',
    addToCartButtonTextColor: '#ffffff',
    productErrorMessageBackground: '#ff0000',
    productErrorMessageText: '#ffffff',
    ctaButtonColor: '#000000',
    ctaButtonTextColor: '#ffffff',
    borderColor: '#D5D5D5',
};

export function StyleOverwrites({ children, shopStyle }: StyleOverwritesProps) {
    if(shopStyle === undefined) {
        shopStyle = Default;
    }

    const styleOverrides = useMemo<ShopStyleVariables>(() => ({
        "--shop-panelBackgroundColor": (shopStyle.panelBackgroundColor || Default.panelBackgroundColor) as string, 
        "--shop-textColor": (shopStyle.textColor || Default.textColor) as string,  
        "--shop-removeFromCartButtonColor": (shopStyle.removeFromCartButtonColor || Default.removeFromCartButtonColor) as string,  
        "--shop-removeFromCartButtonTextColor": (shopStyle.removeFromCartButtonTextColor || Default.removeFromCartButtonTextColor) as string,
        "--shop-addToCartButtonColor": (shopStyle.addToCartButtonColor || Default.addToCartButtonColor) as string,      
        "--shop-addToCartButtonTextColor": (shopStyle.addToCartButtonTextColor || Default.addToCartButtonTextColor) as string,  
        "--shop-productErrorMessageBackground": (shopStyle.productErrorMessageBackground || Default.productErrorMessageBackground) as string,  
        "--shop-productErrorMessageText": (shopStyle.productErrorMessageText || Default.productErrorMessageText) as string,     
        "--shop-ctaButtonColor": (shopStyle.ctaButtonColor || Default.ctaButtonColor) as string,  
        "--shop-ctaButtonTextColor": (shopStyle.ctaButtonTextColor || Default.ctaButtonTextColor) as string,  
        "--shop-borderColor": (shopStyle.borderColor || Default.borderColor) as string,
    }), [shopStyle]);
    return (
        <div style={styleOverrides as React.CSSProperties}>
            {children}
        </div>
    );
}