import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { Shop, TicketApi } from '@/lib/TicketApi';
import { eventEmitter, ReloadShopConfigPayload } from '@/lib/eventEmitter';
import * as m from "@/paraglide/messages.js"
import { StyleOverwrites } from './StyleOverwrites';

interface ShopContextType {
  shop: Shop;
  isLoading: boolean;
  shopSlug: string;
  baseUrl: string;
  isWidget: boolean;
  error: string | null;
}

const ShopContext = createContext<ShopContextType | undefined>(undefined);

interface ShopProviderProps {
  shop?: Shop;
  shopSlug: string;
  baseUrl: string;
  children: ReactNode;
  isWidget?: boolean;
}

export interface ShopParams {
  language: string;
  unlockedProductIds: string[];
}

export const ShopProvider: React.FC<ShopProviderProps> = ({ shopSlug, children, shop: initialShop, baseUrl, isWidget }) => {
  const [shop, setShop] = useState<Shop | null>(initialShop ?? null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [shopParams, setShopParams] = useState<ShopParams>({
    language: 'en',
    unlockedProductIds: [],
  });

  const fetchShopConfig = async (params: ShopParams) => {
    try {
      setIsLoading(true);
      const ticketApi = new TicketApi(baseUrl, shopSlug);
      const shop = await ticketApi.loadShop(params.language, params.unlockedProductIds);
      setShop(shop);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchShopConfig(shopParams);
  }, [shopSlug]);

  useEffect(() => {
    const handleReloadShopConfig = (payload: ReloadShopConfigPayload) => {

      if(payload.shopSlug !== shopSlug) {
        return;
      }

      let somethingChanged = false;
      if(payload.language !== undefined && payload.language !== shopParams.language) {
        somethingChanged = true;
      }

      if(payload.unlockedProductIds !== undefined && payload.unlockedProductIds.length !== shopParams.unlockedProductIds.length && payload.unlockedProductIds.some(id => !shopParams.unlockedProductIds.includes(id))) {
        somethingChanged = true;
      }

      if(!somethingChanged) {
        return;
      }

      const newShopParams = {
        language: payload.language ?? shopParams.language,
        unlockedProductIds: payload.unlockedProductIds ?? shopParams.unlockedProductIds,
      }

      setShopParams(newShopParams);
      fetchShopConfig(newShopParams);
    };

    eventEmitter.on('RELOAD_SHOP_CONFIG', handleReloadShopConfig);

    return () => {
      eventEmitter.off('RELOAD_SHOP_CONFIG', handleReloadShopConfig);
    };
  }, []);

  if(shop === null) {
    return <div>{m.loading()}...</div>;
  }

  const value = {
    shop,
    isLoading,
    error,
    shopSlug,
    baseUrl,
    isWidget: isWidget ?? false,
  };


  return <ShopContext.Provider value={value}>
    <StyleOverwrites shopStyle={shop.style}>
      {children}
    </StyleOverwrites>
  </ShopContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useShop = () => {
  const context = useContext(ShopContext);
  if (context === undefined) {
    throw new Error('useShop must be used within a ShopProvider');
  }
  return context;
};

export const useShopWhenAvailable = () => {
  const context = useContext(ShopContext);
  if (context === undefined) {
    return null;
  }
  return context;
};
