import { MarketplaceApi } from '@/lib/MarketplaceApi';
import { useState } from 'react';

export function WaitlistSignup({slug}: {slug: string}) {
    const [waitlistEmail, setWaitlistEmail] = useState('');
    const [success, setSuccess] = useState(false);

    const handleJoinWaitlist = async () => {
        const marketplaceApi = new MarketplaceApi(import.meta.env.VITE_API_URL);
        await marketplaceApi.joinWaitlist(slug, waitlistEmail);

        setWaitlistEmail('');
        setSuccess(true);
    }

    return (
        <>
            {success && <p className='text-sm mt-2'>You have been added to the waitlist</p>}
            {!success && <>
                <div className='mt-4 flex gap-4'>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="you@example.com"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => {
                            setWaitlistEmail(e.target.value);
                        }}
                        value={waitlistEmail}
                    />
                    <div className='bg-black text-white px-4 py-2 rounded-md grow text-nowrap hover:cursor-pointer hover:opacity-80' onClick={handleJoinWaitlist}>
                        Join the waitlist
                    </div>
                </div>
                <p className='text-sm mt-2'>Get notified when tickets become available</p>
            </>}
        </>
    );
} 