import React, { useState } from 'react';
import { DialogClose, DialogContent, DialogTitle } from '../shared/Dialog';
import { OrderApi, Ticket, TicketStatus } from '@/lib/OrderApi';
import { ImportInfo } from './ImportInfo';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { Money } from '@/lib/money';
import { useLanguage } from '@/providers/LanguageProvider';
import { useIsMobile } from '@/hooks/useIsMobile';
import { RiCashLine, RiCloseLine, RiShare2Line } from '@remixicon/react';
import { shareOnMobile } from 'react-mobile-share';
import copy from 'copy-to-clipboard';
import { useEvent } from '@/providers/EventProvider';
import * as m from "@/paraglide/messages.js"
import { shopVar } from '@/lib/shopStyles';

interface ListTicketModalProps {
    marketplaceUrl: string|null,
    orderId: string,
    ticket: Ticket,
    onSuccess: () => void,
}

const ListTicketModal: React.FC<ListTicketModalProps> = ({orderId, ticket, onSuccess, marketplaceUrl}) => {

  const { currentLanguage: lang } = useLanguage();
  
  const [loading, setLoading] = useState(false);

  const [inMemoryTicket, setTicket] = useState<Ticket>(ticket);

  // const [price, setPrice] = useState<number>((ticket.price.amount + ticket.serviceFee.amount) / 100);
  const [price, setPrice] = useState<string>(((ticket.price.amount + ticket.serviceFee.amount) / 100).toString());
  const [priceError, setPriceError] = useState<string | undefined>(undefined);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  
  const [showCopied, setShowCopied] = useState(false);

  const { event } = useEvent();
  const isMobile = useIsMobile();

  const shareUrl = () => {
    if(inMemoryTicket.ticketStatus !== TicketStatus.forSale) {
      return;
    }

    return `${marketplaceUrl}?listing=${inMemoryTicket.listingId}`;
  }

  const copyOrShareUrl = () => {
    if(inMemoryTicket.ticketStatus !== TicketStatus.forSale) {
      return;
    }

    const url = shareUrl();

    if(url === undefined) {
      return;
    }
    
    if (isMobile) {
      shareOnMobile({
        text: `I'm selling my ticket for ${event?.name}`,
        url,
        title: `I'm selling my ticket for ${event?.name}`,
        images: [],
      });
    } else {
      copy(url, {
        message: "Share link copied to clipboard",
      });
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 3000);
    }
  };

  async function sellTicket(): Promise<void> {
    setLoading(true);
    try {
      const ticketApi = new OrderApi(import.meta.env.VITE_API_URL);
      const listingId = await ticketApi.listTicket(orderId, ticket.ticketId, Money.fromInteger(Number(price) * 100, 'eur'));
      setTicket(
        {
          ...ticket,
          ticketStatus: TicketStatus.forSale,
          listingPriceExFee: {
            amount: Number(price) * 100,
            currency: 'eur',
          },
          listingId: listingId,
        }
      );
      onSuccess();
  } catch (error) {
      console.error(error);
  } finally {
      setLoading(false);
  }
  }

  let limit = 0;

  if((ticket.ticketStatus === TicketStatus.available || ticket.ticketStatus === TicketStatus.claimable) && ticket.canResell) {
    limit = ticket.maxResellPrice.amount;
  }

  const handleOnValueChange: CurrencyInputProps['onValueChange'] = (_value) => {
    setPriceError(undefined);
    if (!_value) {
      setPrice('');
      return;
    }

    // value is over limit
    if (Number(_value) > (limit/100)) {
      setPriceError(`Can't be more than ${Money.fromInteger(limit, 'eur').display(lang)}`);
      return;
    }

    setPrice(_value);
  };

  if(inMemoryTicket.ticketStatus === TicketStatus.forSale) {
    console.log(inMemoryTicket);
  }

  const unlistTicket = async () => {
    setLoading(true);
    setError(undefined);
    const ticketApi = new OrderApi(import.meta.env.VITE_API_URL);
    const result = await ticketApi.unlistTicket(orderId, ticket.ticketId);
    if(result === true) {
      onSuccess();
      window.location.reload();
    } else {
      setError("Cant unlist ticket at this moment: " + result);
    }
    setLoading(false);
  }

  return (
      <DialogContent>
        <DialogTitle className='text-center mx-auto'>{ticket.productName} <span className='text-xs tracking-tight'> {ticket.numberOfProductType}/{ticket.totalOfProductType}</span></DialogTitle>
        {inMemoryTicket.ticketStatus === TicketStatus.forSale && (
          <div>
            <p className="text-xl font-bold mt-6">
              Your ticket is listed for {Money.fromInteger(inMemoryTicket.listingPriceExFee.amount, inMemoryTicket.listingPriceExFee.currency).display(lang)}
            </p>

            <ImportInfo className='pt-4 pb-2'>
                <ImportInfo.Item>Once sold you will get an email, the funds will be deposited witin 5 business days.</ImportInfo.Item>
                <ImportInfo.Item>If you change your mind you can always cancel the listing, but once sold it is irreversible</ImportInfo.Item>
                <ImportInfo.Item>Find and share your listing using the URL: <a href={shareUrl()} target="_blank" className='underline'>{shareUrl()}</a></ImportInfo.Item>
              </ImportInfo>

              <div className='flex flex-col items-end'>
                <button className="bg-black text-white p-2 mt-4 rounded-md text-center w-full hover:bg-gray-800 transition-all duration-300 hover:shadow-md disabled:opacity-50 flex items-center justify-center gap-2" onClick={copyOrShareUrl}>
                  {isMobile ? "Share ticket" : "Copy share link to clipboard"} <RiShare2Line className='w-6 h-6' />
                </button>
                {showCopied && <div className='pt-1 text-xs text-gray-500'>Share link copied to clipboard</div>}
              </div>

              <button className="bg-black text-white p-2 mt-4 rounded-md text-center block w-full hover:bg-gray-800 transition-all duration-300 hover:shadow-md disabled:opacity-50 flex items-center justify-center gap-2" disabled={loading} onClick={() => unlistTicket()}>
                {loading ? "loading..." : "Cancel listing"}
                <RiCloseLine className='w-6 h-6' />
              </button>

              {error && <p className="mt-2 text-sm text-red-600">{error}</p>}

              {/* <button className='bg-black text-white p-2 rounded-md text-center block w-full hover:bg-gray-800 transition-all duration-300 hover:shadow-md mt-4'>
                  Cancel share
              </button> */}

              <DialogClose asChild>
                <button className='bg-black text-white p-2 rounded-md text-center block w-full hover:bg-gray-800 transition-all duration-300 hover:shadow-md mt-4'>
                    {m.close()}
                </button>
            </DialogClose>
          </div>
        )}
        {(inMemoryTicket.ticketStatus === TicketStatus.claimable || inMemoryTicket.ticketStatus === TicketStatus.available) && inMemoryTicket.canResell && (
          <div className="">
            <p className="text-xl font-bold mt-6">
              About the marketplace
            </p>

            <ImportInfo className='pt-4 pb-2'>
              <ImportInfo.Item>You can only add <b>{inMemoryTicket.maxResellPercentage}</b>% to the original price</ImportInfo.Item>
              {marketplaceUrl && <ImportInfo.Item>Your listing will be shown on the website: <a href={marketplaceUrl} target="_blank" className='underline'>{marketplaceUrl}</a></ImportInfo.Item>}
              <ImportInfo.Item>If you change your mind you can always cancel the listing, but <b>once sold it is irreversible</b></ImportInfo.Item>
            </ImportInfo>

            <p className='pt-4'>For what price will you list it?</p>
            <CurrencyInput
              id="validationCustom01"
              name="input-1"
              className="mt-4 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              decimalScale={2}
              decimalSeparator='.'
              groupSeparator=","
              value={price}
              onValueChange={handleOnValueChange}
              placeholder="Please enter a number"
              prefix='€'
              step={1}
            />
            {!priceError && <p className="mt-2 text-sm text-gray-500">This price can not be changed while listed</p>}
            {priceError && <p id="price-error" className="mt-2 text-sm text-red-600">{priceError}</p>}
          

            <div className="relative flex items-start mt-4">
              <div className="flex h-6 items-center">
                <input
                  id="terms"
                  name="terms"
                  type="checkbox"
                  aria-describedby="terms-description"
                  className="h-4 w-4 rounded border-gray-300 focus:ring-indigo-600"
                  style={{
                    color: shopVar('--shop-textColor'),
                  }}
                  checked={agreeToTerms}
                  onChange={() => setAgreeToTerms(!agreeToTerms)}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900">
                    {m.agree_to_terms_pt1()}<a href="https://www.fastlane.events/fastlane-terms-conditions/fastlane-terms-and-conditions-for-ticketing-services" className="font-bold" target="_blank">Fastlane ticketing</a>
                </label>
              </div>
            </div>

              <button className="bg-black text-white p-2 mt-4 rounded-md text-center block w-full hover:bg-gray-800 transition-all duration-300 hover:shadow-md disabled:opacity-50 flex items-center justify-center gap-2" disabled={loading || !agreeToTerms || priceError !== undefined} onClick={() => sellTicket()}>
                {loading ? "loading..." : "Sell ticket"}
                <RiCashLine className='w-6 h-6' />
              </button>
          </div>
        )}
      </DialogContent>
  );
};

export default ListTicketModal;
