import { CartItems } from '@/components/marketplace/CartItems';
import OrderPageShell from '@/components/order/OrderPageShell'
import { SelectPaymentMethod } from '@/components/purchase/checkout/SelectPaymentMethod';
import MainButton from '@/components/shared/MainButton';
import { Panel } from '@/components/shared/Panel';
import { calculateFee } from '@/lib/calculateFee';
import { Money } from '@/lib/money';
import { shopVar } from '@/lib/shopStyles';
import { PaymentMethod } from '@/lib/TicketApi';
import { useLanguage } from '@/providers/LanguageProvider';
import { useMarketplaceCart } from '@/providers/MarketplaceCartProvider';
import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react';
import { createFileRoute } from '@tanstack/react-router'
import { useEffect, useState } from 'react';

export const Route = createFileRoute('/marketplace/$slug/checkout/$cart')({
  component: Checkout
})

function Checkout() {
  // const { marketplace, cart, paymentMethod, setPaymentMethod, renderCartItems, paymentMethodFee, cartTotal } = Route.useRouteContext();
  const cartId = Route.useParams().cart;
  const navigate = Route.useNavigate();
  const { currentLanguage: lang } = useLanguage();
  const {checkoutLoading, cartTotal, startCheckout, marketplace, formState, formValid, cart, getCartItemTotal, setCartIdFromRouteSlug} = useMarketplaceCart();

  useEffect(() => {
    setCartIdFromRouteSlug(cartId);
  }, [cartId]);

  const [paymentMethod, setPaymentMethod] = useState<string|null>(marketplace.paymentMethods[0].id);

  const paymentMethodFee = (): Money|null => {
    if(!paymentMethod) return null;
    const selectedPaymentMethod: PaymentMethod|undefined = marketplace.paymentMethods.find(pm => pm.id === paymentMethod);
    if(!selectedPaymentMethod) return null;

    return calculateFee(selectedPaymentMethod.fee, cartTotal, 1);
  }

  const selectedPaymentMethod = (): PaymentMethod|null => {
      if(!paymentMethod) return null;
      const selectedPaymentMethod: PaymentMethod|undefined = marketplace.paymentMethods.find(pm => pm.id === paymentMethod);
      if(!selectedPaymentMethod) return null;
      return selectedPaymentMethod;
  }

  return <OrderPageShell event={marketplace.event}>
    <Panel>

      {cart.pendingPayment && (
        <div>
          <div>Payment pending...</div>
        </div>
      )}

      {cart.orderId && (
        <div>
          <div>Payment successful!</div>
        </div>
      )}

      {!cart.pendingPayment && !cart.orderId && (
        <SelectPaymentMethod 
            className='py-6'
            paymentMethods={marketplace.paymentMethods}
            selectedMethod={paymentMethod}
            onMethodChange={setPaymentMethod}
        />
      )}
              <CartItems reserved={cart.reserved} listings={marketplace.listings} getCartItemTotal={getCartItemTotal} />
              <div className='flex flex-col items-end gap-2 mt-2 pt-2 border-t border-dashed' style={{
                  borderColor: shopVar('--shop-borderColor')
              }}>
                  {paymentMethodFee() && !paymentMethodFee()!.isZero() && (
                      <>
                          <div className='text-sm tracking-tight'>Subtotaal: {cartTotal.display(lang)}</div>
                          <div className='text-sm tracking-tight'>{selectedPaymentMethod()?.fee.name}: {paymentMethodFee()!.display(lang)}</div>
                      </>
                  )}
                  <div className='font-bold text-2xl'>{cartTotal.add(paymentMethodFee() || Money.zero(cartTotal.currency)).display(lang)}</div>
              </div>
                {cart.orderId && (
                  <MainButton 
                    className='flex-grow mt-4'
                    onClick={() => {
                        navigate({ to: '/order/$orderId', params: { orderId: cart.orderId! }});
                    }}>
                    <div className='text-lg font-bold leading-7'>Collect your order</div>
                    <RiArrowRightSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                </MainButton>
                )}

                {!cart.orderId && (
                  <div className='flex gap-2 mt-4'>
                    {!cart.pendingPayment && (
                      <div className='border-2 border-black rounded-md p-2 hover:cursor-pointer hover:opacity-80 hover:bg-gray-100' onClick={async () => {
                        navigate({ to: '/marketplace/$slug', params: { slug: marketplace.slug }, search: { listing: undefined }});
                      }}>
                          <RiArrowLeftSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                      </div>
                    )}
                    <MainButton disabled={!formValid || !paymentMethod || checkoutLoading || cart.pendingPayment} 
                        className='flex-grow'
                        onClick={async () => {
                            if(!paymentMethod) return;
                            if(cart.pendingPayment) return;
                            // await submitFormAnswers();
                            startCheckout(formState, paymentMethod);
                        }}>
                        <div className='text-lg font-bold leading-7'>Checkout</div>
                        <RiArrowRightSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                    </MainButton>
                </div>
              )}
          </Panel>
  </OrderPageShell>
}
