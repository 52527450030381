import { Money } from '@/lib/money';
import { displayFee } from '@/lib/displayFee';
import type { CartItem, Listing } from '@/lib/MarketplaceApi';
import { useLanguage } from '@/providers/LanguageProvider';

interface CartItemsProps {
  reserved: CartItem[];
  listings: Listing[];
  getCartItemTotal: (reserved: CartItem) => Money;
}

export function CartItems({ reserved, listings, getCartItemTotal }: CartItemsProps) {
  const { currentLanguage: lang } = useLanguage();
  return (
    <>
      {reserved.map((reserved) => (
        <div key={reserved.listingId} className="flex items-center justify-between py-2">
          <div className='flex flex-col'>
            <span className='font-semibold py-1'>
              {reserved.quantity}x {listings.find(l => l.id === reserved.listingId)?.product_name}
            </span>
            <span className='text-sm tracking-tight'>
              {Money.fromInteger(reserved.price.amount, reserved.price.currency).display(lang)} {displayFee(reserved.fee, lang, '+ ', " " + reserved.fee.name)}
            </span>
          </div>
          <div className='flex flex-col items-end'>
            <span className='font-bold text-xl'>{getCartItemTotal(reserved).display(lang)}</span>
          </div>
        </div>
      ))}
    </>
  );
} 