import { createFileRoute } from '@tanstack/react-router'
import { OrderApi } from '@/lib/OrderApi'
import OrderPageShell from '@/components/order/OrderPageShell';
import AvailableTicket from '@/components/order/AvailableTicket';

export const Route = createFileRoute('/shared-ticket/$ticketId')({
    loader: async ({ params }: { params: { ticketId: string } }) => {
        const ticketApi = new OrderApi(import.meta.env.VITE_API_URL);
        return { sharedTicket: await ticketApi.getSharedTicket(params.ticketId)}
    },
  component: SharedTicket
})

function SharedTicket() {
    const { sharedTicket } = Route.useLoaderData();
    return (<OrderPageShell event={sharedTicket.event}>
        <div className='bg-white px-8 -mt-4 rounded-b-md'>
            <div className='w-full border-b border-gray-600 pb-4 mb-4 border-dashed'>
            </div>
            <div className='max-w-xs mx-auto pb-8'>
                <div className='text-center mx-auto mb-4'>{sharedTicket.ticket.productName} <span className='text-xs tracking-tight'> {sharedTicket.ticket.numberOfProductType}/{sharedTicket.ticket.totalOfProductType}</span></div>
                <AvailableTicket ticket={sharedTicket.ticket} />
            </div>
        </div>
    </OrderPageShell>)

}