import {useShop} from "@/providers/ShopProvider";
import { CartStep, useCart } from '@/providers/CartProvider';
import PaymentStatus from './PaymentStatus';
import {Panel} from '@/components/shared/Panel';
import { shopVar } from '@/lib/shopStyles';
import {MainButton} from '@/components/shared/MainButton';
import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react';
import { useEvent } from '@/providers/EventProvider';
import { OrganisationLegalInformation } from '@/components/OrganisationLegalInformation';
import { useLanguage } from '@/providers/LanguageProvider';
import * as m from "@/paraglide/messages.js"
import { SelectPaymentMethod } from './SelectPaymentMethod';

export function Checkout() {
    const {shop} = useShop(); 
    const {cart, costOverview, paymentMethod, setPaymentMethod, startCheckout, loading, setStep} = useCart();
    const {eventId} = useEvent();

    const { currentLanguage: lang } = useLanguage();

    const startPayment = async () => {
        const paymentResponse = await startCheckout(eventId);
        if(paymentResponse.type === "redirect") {
            window.location.href = paymentResponse.url as string;
        }

        // if(paymentResponse.type === 'no_payment_required') {
        //     // wait for order to become available
        //     // reload cart..
        //     const interval = setInterval(() => {
        //         reloadCart();

        //         console.log(cart);

        //         if(cart !== null && cart.orderId !== null) {
        //             clearInterval(interval);
        //             window.location.href = `${rootUrl}/order/${cart.orderId}`;
        //         }
        //     }, 500);
        // }
        else {
            console.log(paymentResponse);
        }
    }

    if(cart === null){
        return <div>Loading...</div>;
    }

    const overview = costOverview();

    const rootUrl = import.meta.env.VITE_SHOP_ROOT_URL;

    return (
        <>
        {!overview.total.isZero() && cart.pendingPayment === null && cart.isCheckedOut === false && (
            <Panel>
                <SelectPaymentMethod 
                    paymentMethods={shop.paymentMethods}
                    selectedMethod={paymentMethod}
                    onMethodChange={setPaymentMethod}
                />
            </Panel>
        )}
        <Panel>
            <PaymentStatus />
            {overview.products.map((product) => (
                    <div key={product.productId} className="flex items-center justify-between py-2">
                        <div className='flex flex-col'>
                            <span className='font-semibold py-1'>{product.quantity}x {product.name}</span>
                            <span className='text-sm tracking-tight'>{product.unit.display(lang)} {product.feeUnit && `+ ${product.feeUnit.display(lang)} ${m.fee()}`}</span>
                        </div>
                        <div className='flex flex-col items-end'>
                            {/* <span className='text-sm tracking-tight'>{product.quantity} x {product.unitInclFee.display(lang)}</span> */}
                            <span className='font-bold text-xl'>{product.totalInclFee.display(lang)}</span>
                        </div>
                    </div>
                ))}
                <div className='flex flex-col items-end gap-2 mt-2 pt-2 border-t border-dashed' style={{
                    borderColor: shopVar('--shop-borderColor')
                }}>
                    {overview.paymentFee && (
                        <>
                            <div className='text-sm tracking-tight'>{m.subtotal()}: {overview.total.subtract(overview.paymentFee.total).display(lang)}</div>
                            <div className='text-sm tracking-tight'>{overview.paymentFee.name}: {overview.paymentFee.total.display(lang)}</div>
                        </>
                    )}
                    <div className='font-bold text-2xl'>{m.total()} {overview.total.display(lang)}</div>
                </div>
                {!cart.isCheckedOut && cart.pendingPayment === null && (<>
                    <div className='mt-4 mb-2 flex gap-2'>
                        <div className='border-2 border-black rounded-md p-2 hover:cursor-pointer hover:opacity-80 hover:bg-gray-100' onClick={() => {
                            setStep(CartStep.Order);
                            }}>
                            <RiArrowLeftSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                        </div>
                        <MainButton onClick={startPayment} disabled={loading} className='flex-grow'>
                            {loading ? <div className='text-lg font-bold leading-7'>{m.loading_button_prefix()} {overview.total.isZero() ? m.loading_btn_tickets_vartiant() : m.loading_button_payment_varitant()}</div> : <>
                                <div className='text-lg font-bold leading-7'>{Object.values(cart.payments).length !== 0 ? m.retry_payment() : overview.total.isZero() ? m.checkout_claim_tickets() : m.checkout_proceed_to_payment()}</div>
                                <RiArrowRightSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                            </>}
                        </MainButton>
                </div>
                <OrganisationLegalInformation/>
                </>)}
                {cart.pendingPayment !== null && (<>
                    <MainButton disabled={true} className='mt-4 mb-2'>
                        {m.processing_payment()}
                    </MainButton>
                </>)}
                {cart.isCheckedOut && (<>
                    <MainButton disabled={false} url={`${rootUrl}/order/${cart.orderId}`} newTab={!window.location.href.includes(rootUrl)} className='mt-4 mb-2'>
                        <div className='text-lg font-bold leading-7'>{m.cart_is_checked_out_collect_order()}</div>
                        <RiArrowRightSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                    </MainButton>
                </>)}
        </Panel>
        </>
    );
}