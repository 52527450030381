import React, { useState } from 'react';
import { OrderApi, Ticket, TicketStatus } from '@/lib/OrderApi';
import { CustomerDetailsForm } from '@/components/customer-details-form/CustomerDetailsForm';

interface ClaimTicketProps {
  orderId: string;
  ticket: Ticket;
  type: "self" | "shared";
  answers: object;
  onSuccess: () => void;
}

const ClaimTicket: React.FC<ClaimTicketProps> = ({ orderId, ticket, type, answers, onSuccess }) => {

    const [formState, setFormState] = useState<object>(type === "self" ? answers : {});
    const [formValid, setFormValid] = useState(false);
    const [loading, setLoading] = useState(false);

    if(ticket.ticketStatus !== TicketStatus.claimable) {
        return null;
    }

    const claimTicket = async () => {
        if(!formValid) {
            return;
        }

        setLoading(true);
        try {
            const ticketApi = new OrderApi(import.meta.env.VITE_API_URL);
            await ticketApi.selfClaimTicket(orderId, ticket.ticketId, formState);
            onSuccess();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }
    
  return (
    <div className="flex flex-col gap-4">
      <p className="text-center text-sm text-gray-600">
        Claim ticket
      </p>
      <CustomerDetailsForm
        form={ticket.claimForm}
        answers={formState}
        errors={[]}
        setAnswers={setFormState}
        setFormValid={setFormValid}
      />
      <button className="bg-black text-white p-2 rounded-md text-center block w-full hover:bg-gray-800 transition-all duration-300 hover:shadow-md disabled:opacity-50" disabled={!formValid || loading} onClick={claimTicket} >
        {loading ? "Claiming ticket..." : "Claim ticket"}
      </button>
    </div>
  );
};

export default ClaimTicket;
