import { MoneyValues, ShopStyle, ServiceFee, Event, FormComponent, OrderedList, PaymentMethod } from "./TicketApi";


export type Listing = {
    id: string;
    price: MoneyValues;
    fee: ServiceFee;
    available: number;
    product_id: string;
    product_name: string;
}

export type Marketplace = {
    name: string;
    style: ShopStyle;
    event: Event;
    slug: string;
    listings: Listing[];
    forSaleCount: number;
    soldCount: number;
    waitlistCount: number;
    form: OrderedList<FormComponent>;
    paymentMethods: PaymentMethod[];
}

export interface CartItem {
    listingId: string;
    quantity: number;
    price: MoneyValues;
    fee: ServiceFee;
}

export interface CartState {
    cartId: string;
    reserved: CartItem[];
    answers: object;
    pendingPayment: boolean;
    orderId: string|null;
    listings: Listing[];
}

export interface CartStateWithErrors extends CartState {
    error: string;
}

export class MarketplaceApi {
    constructor(
        private readonly baseUrl: string,
    ) {
    }

    public async getMarketplace(slug: string): Promise<Marketplace> {
        const res = await fetch(`${this.baseUrl}/marketplace/${slug}`, {cache: "no-cache"});
        return await res.json();
    }

    public async getCart(slug: string, cartId: string): Promise<CartState> {
        const response = await fetch(`${this.baseUrl}/marketplace/${slug}/cart/${cartId}`, {
            headers: {
                'Accept': 'application/json',
            },
        });

        if(response.ok) {
            return await response.json();
        } else {
            return {
                reserved: [],
                cartId: cartId,
                answers: {},
                pendingPayment: false,
                orderId: null,
                listings: [],
            };
        }
    }

    public async reserveListing(slug: string, cartId: string, listingId: string, quantity: number): Promise<CartState|CartStateWithErrors> {
        try {
            const response = await fetch(`${this.baseUrl}/marketplace/${slug}/cart/${cartId}/reserve-listing`, {
                method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({listingId, quantity}),
        });
        if(response.ok) {
            return await response.json();
        } else {
            const cart = await this.getCart(slug, cartId);
            return {
                ...cart,
                error: "Failed to reserve tickets, please try again.",
            };
        }
        } catch (error) {
            console.error('Failed to reserve listing:', error);
            // reload cart
            return await this.getCart(slug, cartId);
        }
    }

    public async checkout(slug: string, cartId: string, formAnswers: object, paymentMethodId: string): Promise<{type: "redirect", url: string}|{type: "error", error: string}> {
        const response = await fetch(`${this.baseUrl}/marketplace/${slug}/cart/${cartId}/checkout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({answers: formAnswers, paymentMethodId, redirectAfterPaymentUrl: `${window.location.origin}/marketplace/${slug}/checkout/${cartId}`}),
        });
        return await response.json();
    }

    public async joinWaitlist(slug: string, email: string): Promise<void> {
        const response = await fetch(`${this.baseUrl}/marketplace/${slug}/join-waitlist`, {
            method: 'POST',
            body: JSON.stringify({email}),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        await response.json();
    }

}
