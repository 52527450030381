/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * @param {{ companyName: NonNullable<unknown>, chamberOfCommerceNumber: NonNullable<unknown>, vatNumber: NonNullable<unknown>, addressLine: NonNullable<unknown>, termsAndConditionsUrl: NonNullable<unknown>, contactEmail: NonNullable<unknown>, contactPhone: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legal_text = (params) => `U koop ticket van de organisator <b>${params.companyName}</b>, (KVK:${params.chamberOfCommerceNumber}/BTW:${params.vatNumber}) welke kantoor houdt aan ${params.addressLine}. Door deze aankoop af te ronden gaat u akkoord met de <a href="${params.termsAndConditionsUrl}" target="_blank" class='font-bold underline' rel="noopener noreferrer">algemene voorwaarden en privacyvoorwaarden  </a> en u kunt dit bedrijf bereiken via <a href="mailto:${params.contactEmail}">${params.contactEmail}</a> or ${params.contactPhone}    `
