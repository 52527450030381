interface CurrencyInfo {
    decimal_digits: number;
    // Add other currency-specific properties here as needed
}

class Money {
    public readonly amount: number;
    public readonly currency: string;

    private static currencyInfoMap: { [key: string]: CurrencyInfo } = {
        'EUR': { decimal_digits: 2 },
    };

    private constructor(amount: number, currency: string) {
        this.amount = amount;
        this.currency = currency.toUpperCase();
    }

    public static fromInteger(amount: number, currency: string): Money {
        return new Money(amount, currency);
    }

    public static zero(currency: string): Money {
        return new Money(0, currency);
    }

    public multiply(quantity: number): Money {
        return new Money(Math.round(this.amount * quantity), this.currency);
    }

    public divide(divisor: number): Money {
        if (divisor === 0) {
            throw new Error("Cannot divide by zero");
        }
        return new Money(Math.round(this.amount / divisor), this.currency);
    }

    public add(other: Money): Money {
        this.assertSameCurrency(other);
        return new Money(this.amount + other.amount, this.currency);
    }

    public subtract(other: Money): Money {
        this.assertSameCurrency(other);
        return new Money(this.amount - other.amount, this.currency);
    }

    public isZero(): boolean {
        return this.amount === 0;
    }

    public getCurrencyInfo(): CurrencyInfo {
        const info = Money.currencyInfoMap[this.currency];
        if (!info) {
            throw new Error(`Currency information not available for ${this.currency}`);
        }
        return info;
    }

    public display(locale: string = 'en') : string {
        return new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: this.currency,
        }).format(this.amount / Math.pow(10, this.getCurrencyInfo().decimal_digits));
    }

    private assertSameCurrency(other: Money): void {
        if (this.currency !== other.currency) {
            throw new Error("Cannot perform operation on different currencies");
        }
    }
}

export { Money };
