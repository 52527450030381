import React, { createContext, useContext } from 'react';
import { Event } from '@/lib/TicketApi';

interface EventContextType {
  eventId: string;
  event: Event;
}

const EventContext = createContext<EventContextType | undefined>(undefined);

interface EventProviderProps {
  event: Event | null;
  children: React.ReactNode;
}

export const EventProvider: React.FC<EventProviderProps> = ({ event, children }) => {
  if (!event) {
    return <div>No event found</div>;
  }

  return <EventContext.Provider value={{ eventId: event.eventId, event: event }}>{children}</EventContext.Provider>;
};

export const useEvent = () => {
  const context = useContext(EventContext);
  if (context === undefined) {
    throw new Error('useEvent must be used within an EventProvider');
  }
  return context;
};
