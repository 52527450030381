// src/contexts/LanguageContext.tsx
import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { sourceLanguageTag, availableLanguageTags, setLanguageTag, languageTag } from '@/paraglide/runtime';

type LanguageContextType = {
  currentLanguage: string;
  changeLanguage: (language: string) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [currentLanguage, setCurrentLanguage] = useState(languageTag());

  const changeLanguage = (language: string) => {
    setLanguageTag(language as typeof sourceLanguageTag);
    setCurrentLanguage(language as typeof sourceLanguageTag);
    localStorage.setItem('preferredLanguage', language);
  };

  useEffect(() => {
    const initializeLanguage = () => {
      const storedLanguage = localStorage.getItem('preferredLanguage');
      if (storedLanguage && availableLanguageTags.includes(storedLanguage as typeof sourceLanguageTag)) {
        changeLanguage(storedLanguage);
        return;
      }

      const browserLanguage = navigator.language.split('-')[0];

      const newLanguage = availableLanguageTags.includes(browserLanguage as typeof sourceLanguageTag) 
        ? browserLanguage 
        : sourceLanguageTag;
      
      changeLanguage(newLanguage);
    };

    initializeLanguage();
  }, []);

  return (
    <LanguageContext.Provider value={{ currentLanguage, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};