import React, { useState } from 'react';
import { DialogClose, DialogContent, DialogTitle } from '../shared/Dialog';
import { OrderApi, Ticket, TicketStatus } from '@/lib/OrderApi';
import { ImportInfo } from './ImportInfo';
import { Switch } from '../catalyst/switch';
import { Money } from '@/lib/money';
import { useLanguage } from '@/providers/LanguageProvider';
import { useIsMobile } from '@/hooks/useIsMobile';
import { RiShare2Line } from '@remixicon/react';
import { shareOnMobile } from 'react-mobile-share';
import copy from 'copy-to-clipboard';
import { useEvent } from '@/providers/EventProvider';
import * as m from "@/paraglide/messages.js"
interface ShareTicketModalProps {
    orderId: string,
    ticket: Ticket,
    onSuccess: () => void,
}



const ShareTicketModal: React.FC<ShareTicketModalProps> = ({orderId, ticket, onSuccess}) => {

  const { currentLanguage: lang } = useLanguage();
  
  const [loading, setLoading] = useState(false);

  const [paidShare, setPaidShare] = useState(false);

  const [inMemoryTicket, setTicket] = useState<Ticket>(ticket);

  const [name, setName] = useState<string | undefined>(undefined);

  const [showCopied, setShowCopied] = useState(false);
  const { event } = useEvent();
  const isMobile = useIsMobile();

  const shareUrl = () => {
    if(inMemoryTicket.ticketStatus !== TicketStatus.shared) {
      return;
    }

    return `${window.location.origin}/ticket-share/${inMemoryTicket.shareCode}`;
  }

  const copyOrShareUrl = () => {
    if(inMemoryTicket.ticketStatus !== TicketStatus.shared) {
      return;
    }

    const url = shareUrl();

    if(url === undefined) {
      return;
    }
    
    if (isMobile) {
      shareOnMobile({
        text: `Your ticket for ${event?.name}`,
        url,
        title: `Your ticket for ${event?.name}`,
        images: [],
      });
    } else {
      copy(url, {
        message: "Share link copied to clipboard",
      });
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 3000);
    }
  };

  async function shareTicket(): Promise<void> {
    setLoading(true);
    try {
      const ticketApi = new OrderApi(import.meta.env.VITE_API_URL);
      const shareCode = await ticketApi.shareTicket(orderId, ticket.ticketId, name, paidShare);

      setTicket(
        {
          ...ticket,
          ticketStatus: TicketStatus.shared,
          shareCode: shareCode,
          shareName: name,
          inviteeShouldPay: paidShare,
        }
      );
      onSuccess();
  } catch (error) {
      console.error(error);
  } finally {
      setLoading(false);
  }
  }

  console.log(inMemoryTicket);
  return (
      <DialogContent>
        <DialogTitle className='text-center mx-auto'>{ticket.productName} <span className='text-xs tracking-tight'> {ticket.numberOfProductType}/{ticket.totalOfProductType}</span></DialogTitle>
        {inMemoryTicket.ticketStatus === TicketStatus.shared && (
          <div>
            <p className="text-xl font-bold mt-6">
              Ticket shared
            </p>

            <ImportInfo className='pt-4 pb-2'>
                <ImportInfo.Item>The ticket has been shared {inMemoryTicket.shareName !== undefined ? `with ${inMemoryTicket.shareName}` : ''}</ImportInfo.Item>
                <ImportInfo.Item>Your friend has to accept the invitation before they can use it</ImportInfo.Item>
                <ImportInfo.Item>Once they accept it, you can't undo the share</ImportInfo.Item>
                {inMemoryTicket.inviteeShouldPay && (
                  <ImportInfo.Item>Your friend has to pay the original price of {Money.fromInteger(ticket.price.amount, ticket.price.currency).display(lang)} and {Money.fromInteger(ticket.serviceFee.amount, ticket.serviceFee.currency).display(lang)} service fee</ImportInfo.Item>
                )}
              </ImportInfo>

              <div className='flex flex-col items-end'>
                <button className="bg-black text-white p-2 mt-4 rounded-md text-center w-full hover:bg-gray-800 transition-all duration-300 hover:shadow-md disabled:opacity-50 flex items-center justify-center gap-2" onClick={copyOrShareUrl}>
                  {isMobile ? "Share ticket" : "Copy share link to clipboard"} <RiShare2Line className='w-6 h-6' />
                </button>
                {showCopied && <div className='pt-1 text-xs text-gray-500'>Share link copied to clipboard</div>}
              </div>

              {/* <button className='bg-black text-white p-2 rounded-md text-center block w-full hover:bg-gray-800 transition-all duration-300 hover:shadow-md mt-4'>
                  Cancel share
              </button> */}

              <DialogClose asChild>
                <button className='bg-black text-white p-2 rounded-md text-center block w-full hover:bg-gray-800 transition-all duration-300 hover:shadow-md mt-4'>
                    {m.close()}
                </button>
            </DialogClose>
          </div>
        )}
        {(inMemoryTicket.ticketStatus === TicketStatus.claimable || inMemoryTicket.ticketStatus === TicketStatus.available) && (
          <div className="">
            <p className="text-xl font-bold mt-6">
              Share your ticket
            </p>

            <ImportInfo className='pt-4 pb-2'>
                <ImportInfo.Item>You can share a link so your friend <b>can claim</b> this ticket</ImportInfo.Item>
                {inMemoryTicket.allowPaidShare && (
                  <ImportInfo.Item>You can give it for <b>free</b> or ask to pay the <b>original price</b></ImportInfo.Item>
                )}
                <ImportInfo.Item>Once you friend accepts the ticket it is theirs to use</ImportInfo.Item>
              </ImportInfo>

              {inMemoryTicket.allowPaidShare && (
                <div className='flex items-center gap-2 py-2'>
                  <Switch className='shrink-0' color="sky" name="paidShare" checked={paidShare} onChange={setPaidShare}/>
                  <span className='text-xs tracking-tight'>Let your friend pay the original price and service fee of {Money.fromInteger(ticket.price.amount, ticket.price.currency).display(lang)} + {Money.fromInteger(ticket.serviceFee.amount, ticket.serviceFee.currency).display(lang)} - you will receive the funds within 48 hours after payment.</span>
                </div>
              )}

              <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="mt-4 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
          
              <button className="bg-black text-white p-2 mt-4 rounded-md text-center block w-full hover:bg-gray-800 transition-all duration-300 hover:shadow-md disabled:opacity-50" disabled={loading} onClick={() => shareTicket()}>
                {loading ? "Sharing ticket..." : "Share ticket"}
              </button>
          </div>
        )}
      </DialogContent>
  );
};

export default ShareTicketModal;
