import { useEffect, useState, useCallback } from "react";
import { isFuture, parseISO } from "date-fns";
import { Product } from "@/lib/TicketApi";
import ProductAvailable from "./ProductAvailabilityTypes/ProductAvailable";
import AvailableBetween from "./ProductAvailabilityTypes/AvailableBetween";

import * as m from "@/paraglide/messages.js"

interface ProductCartButtonProps {
    product: Product;
}

export default function ProductAvailability({ product }: ProductCartButtonProps) {
    const [isAvailable, setIsAvailable] = useState<boolean>(getProductIsAvailable());
    const [isCountdown, setIsCountdown] = useState<boolean>(getIsCountdownActive());

    function getProductIsAvailable(): boolean {
        switch (product.availability.type) {
            case "available": return true;
            case "available_between": {
                const { availableFrom, availableUntil } = product.availability;
                const startDate = availableFrom ? parseISO(availableFrom) : null;
                const endDate = availableUntil ? parseISO(availableUntil) : null;

                if(startDate !== null && isFuture(startDate)){
                    return false;
                }
                return !(endDate !== null && !isFuture(endDate));
            }
            case "notAvailable": return false;
            case "notListed": return false;
            default: return false;
        }
    }

    function getIsCountdownActive(): boolean {
        switch (product.availability.type) {
            case "available": return false;
            case "available_between": {
                const { availableFrom, availableUntil } = product.availability;
                const startDate = availableFrom ? parseISO(availableFrom) : null;
                const endDate = availableUntil ? parseISO(availableUntil) : null;
                // const now = new Date();

                if(startDate === null) {
                    return false;
                }

                if(endDate !== null && !isFuture(endDate)){
                    return false;
                }

                if(!isFuture(startDate)){
                    return false;
                }

                // for now no countdown
                return false;

                // console.log(differenceInHours(startDate, now));

                // return differenceInHours(startDate, now) <= 12;
            }
            case "notAvailable": return false;
            case "notListed": return false;
            default: return false;
        }
    }

    const checkProductAvailability = useCallback(() => {
        setIsAvailable(getProductIsAvailable());
        setIsCountdown(getIsCountdownActive());
    }, [product]);

    useEffect(() => {
        checkProductAvailability();
        const intervalId = setInterval(checkProductAvailability, 100);
        return () => clearInterval(intervalId);
    }, [checkProductAvailability]);

    if(product.availability.type === "available_between") {
        return <AvailableBetween product={product} />;
    }

    if (product.availability.type === "notAvailable" || (!isAvailable && !isCountdown)) {
        return <>{m.product_not_available()}</>
    }

    if (isCountdown) {
        return <>Prod countdown</>
    }

    return <ProductAvailable product={product} />;
}
