import { createFileRoute, Outlet } from '@tanstack/react-router'

import { MarketplaceCartProvider } from '@/providers/MarketplaceCartProvider';

export const Route = createFileRoute('/marketplace/$slug')({
      component: Marketplace
})

function Marketplace() {
    return (
        <MarketplaceCartProvider slug={Route.useParams().slug}>
            <Outlet />
        </MarketplaceCartProvider>
    );
}
