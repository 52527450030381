'use client'

import React, { useRef, useEffect } from 'react'
import { motion, AnimatePresence, useAnimation } from 'framer-motion'
import { useCart } from '@/providers/CartProvider';
import { PaymentStatus as ApiPaymentStatus } from '@/lib/TicketApi'
import { RiCheckboxCircleLine, RiCloseCircleLine, RiTimeLine } from '@remixicon/react';
import { shopVar } from '@/lib/shopStyles';
import * as m from "@/paraglide/messages.js"

const LoaderBars = React.memo(() => {
    const controls = useAnimation();
    const isAnimating = useRef(false);

    useEffect(() => {
        if (!isAnimating.current) {
            isAnimating.current = true;
            controls.start({
                opacity: [0.5, 1, 0.5],
                transition: {
                    duration: 1.5,
                    repeat: Infinity,
                    delay: 0.2,
                    ease: "easeInOut",
                }
            });
        }
    }, [controls]);

    return (
        <div className="flex space-x-1 mt-4">
            <motion.div
                className="h-1 w-full rounded-full overflow-hidden"
                animate={controls}
                style={{
                    backgroundColor: shopVar('--shop-panelBackgroundColor'),
                }}
            >
                <motion.div
                    className="h-full"
                    initial={{ x: "-100%" }}
                    animate={{ x: "100%" }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut",
                    }}
                    style={{
                        backgroundColor: shopVar('--shop-ctaButtonColor'),
                    }}
                />
            </motion.div>
        </div>
    );
});

export default function PaymentStatus() {
    const {cart, currentPayment} = useCart();

    if(cart === null) {
        return null;
    }

    const payment = currentPayment();

    if(payment === null) {
        if(cart.isCheckedOut) {
            return  <motion.div
            key="completed"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            className=""
        >
            <div className="flex flex-col items-center mt-2 mb-6 gap-2">
                <RiCheckboxCircleLine color='#27AE60' className="w-12 h-12"/>
                <span className="font-bold text-xl text-gray-800">{m.cart_checked_out()}</span>
                </div>
            </motion.div>;
        }
        return null;
    }

    return (
        <div className="mx-auto">
            <AnimatePresence mode="wait">
                {payment.status === ApiPaymentStatus.Pending && (
                    <motion.div
                        key="processing"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        className=""
                    >
                        <div className="flex flex-col items-center mt-2 gap-2">
                            <RiTimeLine className="w-12 h-12 opacity-85" style={{color: shopVar('--shop-textColor')}}/>
                            <span className="font-medium text-gray-800">{m.waiting_for_payment()}</span>
                            <p className="text-sm tracking-tight opacity-80" style={{color: shopVar('--shop-textColor')}}>{m.waiting_for_payment_description()}</p>
                        </div>
                        <div className="py-2">
                            <LoaderBars />
                        </div>
                    </motion.div>
                )}
                {payment.status === ApiPaymentStatus.Paid && (
                    <motion.div
                        key="completed"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        className=""
                    >
                        <div className="flex flex-col items-center mt-2 mb-6 gap-2">
                            <RiCheckboxCircleLine color='#27AE60' className="w-12 h-12"/>
                            <span className="font-bold text-xl text-gray-800">{m.payment_successful()}</span>
                        </div>
                    </motion.div>
                )}
                {payment.status !== ApiPaymentStatus.Paid && payment.status !== ApiPaymentStatus.Pending && (
                    <motion.div
                        key="cancelled"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        className=""
                    >
                        <div className="flex flex-col items-center mt-2 mb-6 gap-2">
                            <RiCloseCircleLine color='#9F3B3B' className="w-12 h-12"/>
                            <span className="font-bold text-xl text-gray-800">{m.payment()} {payment.status}</span>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}