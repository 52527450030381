import React from 'react';
import { DialogContent, DialogTitle } from '../shared/Dialog';
import { Ticket, TicketStatus } from '@/lib/OrderApi';
import ClaimTicket from './ticket-claim/ClaimTicket';
interface SelfClaimTicketModalProps {
    orderId: string,
    ticket: Ticket,
    answers: object,
    onSuccess: () => void,
}



const SelfClaimTicketModal: React.FC<SelfClaimTicketModalProps> = ({orderId, ticket, answers, onSuccess}) => {
  return (
      <DialogContent>
        <DialogTitle className='text-center mx-auto'>{ticket.productName} <span className='text-xs tracking-tight'> {ticket.numberOfProductType}/{ticket.totalOfProductType}</span></DialogTitle>
        {ticket.ticketStatus === TicketStatus.claimable && ticket.isSelfClaimable && (<ClaimTicket ticket={ticket} type="self" answers={answers} onSuccess={onSuccess} orderId={orderId} />)}
      </DialogContent>
  );
};

export default SelfClaimTicketModal;
