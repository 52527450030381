import { Money } from "@/lib/money";
import {ServiceFee} from "@/lib/TicketApi";

export function calculateFee(serviceFee: ServiceFee, totalAmount: Money, quantity: number) : Money {
    if(serviceFee.amountPerUnit === undefined && serviceFee.percentage === undefined) {
        return Money.zero(totalAmount.currency);
    }

    if(serviceFee.amountPerUnit === null && serviceFee.percentage === null) {
        return Money.zero(totalAmount.currency);
    }

    if(serviceFee.amountPerUnit !== undefined && serviceFee.amountPerUnit !== null){
        if(serviceFee.amountPerUnit?.amount <= 0) {
            return Money.zero(totalAmount.currency);
        }
        return Money.fromInteger(serviceFee.amountPerUnit.amount, serviceFee.amountPerUnit.currency).multiply(quantity);
    }

    if(serviceFee.percentage === undefined) {
        return Money.zero(totalAmount.currency);
    }

    return totalAmount.multiply(serviceFee.percentage / 100);
}
