import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { type Event } from "./TicketApi"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function displayEventDates(event: Event, lang: string = 'en') {
  const start = new Date(event.startDate);
  const end = new Date(event.endDate);

  const dayFormatter = new Intl.DateTimeFormat(lang, { day: 'numeric' });
  const monthFormatter = new Intl.DateTimeFormat(lang, { month: 'long' });
  const fullDateFormatter = new Intl.DateTimeFormat(lang, { day: 'numeric', month: 'long' });
  const timeFormatter = new Intl.DateTimeFormat(lang, { hour: '2-digit', minute: '2-digit' });

  if (start.getFullYear() === end.getFullYear() &&
      start.getMonth() === end.getMonth() &&
      (start.getDate() === end.getDate() || (start.getDate() === end.getDate() - 1 && end.getHours() < 12))) {
      // Same day
      return `${dayFormatter.format(start)} ${monthFormatter.format(start)} ${timeFormatter.format(start)} - ${timeFormatter.format(end)}`;
  } else if (start.getFullYear() === end.getFullYear() &&
      start.getMonth() === end.getMonth()) {
      // Same month
      return `${dayFormatter.format(start)}-${dayFormatter.format(end)} ${monthFormatter.format(start)}`;
  } else {
      // Different months
      return `${fullDateFormatter.format(start)} - ${fullDateFormatter.format(end)}`;
  }
}
