export interface ParentState {
    value: string;
  }
  
  // Define a union type of all possible event names
  export type TrackingEventName = 
    | 'RELOAD_SHOP_CONFIG'
    | 'PAGE_VIEW'
    | 'BUTTON_CLICK'
    | 'FORM_SUBMIT'
    | 'PURCHASE';
  
  // Define the structure for each event payload
  export interface ReloadShopConfigPayload {
    shopSlug: string;
    language?: string;
    unlockedProductIds?: string[];
  }
  
  export interface PageViewPayload {
    path: string;
    title: string;
  }
  
  export interface ButtonClickPayload {
    buttonId: string;
    buttonText: string;
  }
  
  export interface FormSubmitPayload {
    formId: string;
    formName: string;
  }
  
  export interface PurchasePayload {
    orderId: string;
    total: number;
    currency: string;
  }
  
  // Create a map of event names to their corresponding payloads
  export interface TrackingEventMap {
    RELOAD_SHOP_CONFIG: ReloadShopConfigPayload;
    PAGE_VIEW: PageViewPayload;
    BUTTON_CLICK: ButtonClickPayload;
    FORM_SUBMIT: FormSubmitPayload;
    PURCHASE: PurchasePayload;
  }
  
  type EventCallback<T> = (data: T) => void;
  
  class EventEmitter {
    private events: { [K in TrackingEventName]?: EventCallback<TrackingEventMap[K]>[] } = {};
  
    on<K extends TrackingEventName>(eventName: K, callback: EventCallback<TrackingEventMap[K]>): void {
      if (!this.events[eventName]) {
        this.events[eventName] = [];
      }
      this.events[eventName]!.push(callback);
    }
  
    emit<K extends TrackingEventName>(eventName: K, data: TrackingEventMap[K]): void {
      const callbacks = this.events[eventName];
      if (callbacks) {
        callbacks.forEach(callback => callback(data));
      }
    }
  
    off<K extends TrackingEventName>(eventName: K, callback: EventCallback<TrackingEventMap[K]>): void {
      const callbacks = this.events[eventName];
      if (callbacks) {
        // @ts-expect-error - TypeScript cannot infer the type of the callback function
        this.events[eventName] = callbacks.filter(cb => cb !== callback);
      }
    }
  }
  
  export const eventEmitter = new EventEmitter();