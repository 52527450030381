import React from 'react';
import { ProductPage as ProductPageType } from '@/lib/TicketApi';
import ProductSection from './ProductSection';

interface ProductPageProps {
  productPage: ProductPageType;
}

export const ProductPage: React.FC<ProductPageProps> = ({ productPage }) => {
  return (
    <>
      {productPage.productSections.map((productSection) => (
        <ProductSection key={productSection.id} productSection={productSection} />
      ))}
    </>
  );
};