import React, { useState } from 'react';
import TicketPerf from '../../../assets/order/ticket-perf.svg';
import MobileTicket from '../../../assets/order/mobile-ticket.svg';
import QR from '../../../assets/order/qr.svg';
import TicketClaimedIcon from '../../../assets/order/ticket-claimed.svg';
// import GetTicket from '../../../assets/order/get-ticket.svg';
import { TicketStatus, Ticket as TicketType } from '@/lib/OrderApi';
import { Dialog } from '../shared/Dialog';
import ScanOrDownloadTicketModal from './ScanOrDownloadTicketModal';
// import clsx from 'clsx';
import Sold from '../../../assets/order/sold.svg';
import NotClaimed from '../../../assets/order/not-claimed.svg';
import { RiCashLine, RiShare2Line, RiStore2Line } from '@remixicon/react';
import { Money } from '@/lib/money';
interface TicketProps {
  orderId: string;
  ticket: TicketType;
  answers: object;
  triggeredReload: () => void;
  marketplaceUrl: string|null;
}

import { useLanguage } from '@/providers/LanguageProvider';
import * as m from "@/paraglide/messages.js"
import SelfClaimTicketModal from './SelfClaimTicketModal';
import ShareTicketModal from './shareTicketModal';
// import { ShareTicketWithoutPersonalisationButton } from './ShareTicketButton';
import ListTicketModal from './listTicketModal';
import { ShareTicketWithoutPersonalisationButton } from './ShareTicketButton';

const Ticket: React.FC<TicketProps> = ({orderId, ticket, answers, triggeredReload, marketplaceUrl }) => {

  const { currentLanguage: lang } = useLanguage();
  
  const [ticketScanModalOpen, setTicketScanModalOpen] = useState(false);
  const [selfClaimTicketModalOpen, setSelfClaimTicketModalOpen] = useState(false);
  const [shareTicketModalOpen, setShareTicketModalOpen] = useState(false);
  const [listTicketModalOpen, setListTicketModalOpen] = useState(false);
  
  function renderTicketStatusIcon(ticket: TicketType): React.ReactNode | Iterable<React.ReactNode> {
    switch(ticket.ticketStatus) {
      case TicketStatus.available:
        return <button className='cursor-pointer flex flex-col items-center hover:scale-105 transition-all' onClick={() => {
          setTicketScanModalOpen(true);
        }}>
          <img src={QR} alt="" className='h-12 py-2'/>
          <span className='text-sm text-center tracking-tight'>{m.order_ticket_show_ticket()}</span>
        </button>
      case TicketStatus.forSale:
        return <button className='cursor-pointer flex flex-col items-center hover:scale-105 transition-all' onClick={() => {
          setListTicketModalOpen(true);
        }}>
          <RiStore2Line size={40} className='h-10 py-1'/>
          <span className='text-sm text-center tracking-tight'>For sale on marketplace</span>
        </button>
      case TicketStatus.sealed:
        return <img src={MobileTicket} alt="" className='h-12 py-1'/>
      case TicketStatus.claimable:
        if(ticket.isSelfClaimable) {
          return <button className='cursor-pointer flex flex-col items-center hover:scale-105 transition-all' onClick={() => {
            setSelfClaimTicketModalOpen(true);
          }}>
            <img src={NotClaimed} alt="" className='h-10 py-1'/>
            <span className='text-sm text-center tracking-tight'>Claim ticket</span>
          </button>
        } else {
          return <button className='cursor-pointer flex flex-col items-center hover:scale-105 transition-all' onClick={() => {
            setShareTicketModalOpen(true);
          }}>
            <RiShare2Line size={40} className='h-10 py-1'/>
            <span className='text-sm text-center tracking-tight'>share ticket</span>
          </button>
        }
      case TicketStatus.shared:
        return <button className='cursor-pointer flex flex-col items-center hover:scale-105 transition-all' onClick={() => {
          setShareTicketModalOpen(true);
        }}>
          <RiShare2Line size={40} className='h-10 py-1'/>
          <span className='text-sm text-center tracking-tight'>share ticket (pending)</span>
        </button>
      case TicketStatus.claimed:
        return <div className='flex flex-col items-center'>
          <img src={TicketClaimedIcon} alt="" className='h-10 py-1'/>
          {ticket.claimedBy && <span className='text-sm text-center tracking-tight'>Claimed by {ticket.claimedBy}</span>}
        </div>

      case TicketStatus.sold: 
        return <div className='flex flex-col items-center'>
          <img src={Sold} alt="" className='h-10 py-1'/>
          <span className='text-sm text-center tracking-tight'>Sold on marketplace</span>
        </div>
    }

    // Mobile ticket
    // <img src={MobileTicket} alt="" className='h-12 py-1'/>
    // <span className='text-sm text-center tracking-tight'>Activate mobile ticket</span>
  }

  return (
    <div className="mt-4 flex h-[100px]">
      <div className='bg-white w-[100px] flex flex-col items-center justify-center p-2 rounded-md rounded-tr-none rounded-br-none'>
        {renderTicketStatusIcon(ticket)}
      </div>
      <img src={TicketPerf} alt="" className='h-[100px]'/>
      <div className='bg-white grow -ml-[0.8px] flex flex-col justify-between rounded-md rounded-tl-none rounded-bl-none'>
        <div className='flex px-4 items-center justify-between border-b border-gray-200 grow'>
          <div className='flex items-center'>
            <span className='text-sm leading-normal font-semibold'>{ticket.productName}</span> <span className='ml-1 text-xs tracking-tight'> {ticket.selfClaimed ?? false ? 'Your ticket' : `${ticket.numberOfProductType}/${ticket.totalOfProductType}`}</span>
          </div>
          <Dialog open={ticketScanModalOpen} onOpenChange={setTicketScanModalOpen}>
            <ScanOrDownloadTicketModal ticket={ticket} />
          </Dialog>
          <Dialog open={selfClaimTicketModalOpen} onOpenChange={setSelfClaimTicketModalOpen}>
            <SelfClaimTicketModal ticket={ticket} answers={answers} onSuccess={() => {
              triggeredReload();
              setSelfClaimTicketModalOpen(false);
            }} orderId={orderId}/>
          </Dialog>
          <Dialog open={shareTicketModalOpen} onOpenChange={setShareTicketModalOpen}>
            <ShareTicketModal ticket={ticket} onSuccess={() => {
              triggeredReload();
            }} orderId={orderId}/>
          </Dialog>
          <Dialog open={listTicketModalOpen} onOpenChange={setListTicketModalOpen}>
            <ListTicketModal ticket={ticket} 
            marketplaceUrl={marketplaceUrl}
            onSuccess={() => {
              triggeredReload();
              setListTicketModalOpen(false);
            }} orderId={orderId}/>
          </Dialog>
          {/* <div className={clsx('flex items-center   ', ticket.ticketStatus === TicketStatus.available ? 'opacity-100 hover:underline cursor-pointer' : 'opacity-30 cursor-not-allowed')} onClick={() => {
            if(ticket.ticketStatus !== TicketStatus.available) {
              return
            }
            setTicketScanModalOpen(true);
          }}>
            <img src={GetTicket} alt="" className='w-6 h-6'/>
            <span className='ml-2 tracking-tight font-semibold whitespace-nowrap'>Get Ticket</span>
          </div> */}
        </div>
        <div className='flex px-4 items-center justify-between grow'>
          <span className='text-base leading-normal font-semibold'>{Money.fromInteger(ticket.price.amount, ticket.price.currency).add(Money.fromInteger(ticket.serviceFee.amount, ticket.serviceFee.currency)).display(lang)}</span>
          <div className='flex itemx-center justify-end gap-4'>
            {(ticket.ticketStatus === TicketStatus.available || ticket.ticketStatus === TicketStatus.claimable) && ticket.canResell && (
              <button
                onClick={() => setListTicketModalOpen(true)}
                className='flex items-center hover:underline hover:cursor-pointer hover:bg-gray-100 rounded-md p-2 -m-2'
              >
                <RiCashLine className='w-6 h-6' />
                <span className='ml-2 tracking-tight font-semibold'>Sell</span>
            </button>
            )}
          {ticket.ticketMustBePersonalized && (ticket.ticketStatus === TicketStatus.claimable || ticket.ticketStatus === TicketStatus.available) && (
            <button
              onClick={() => setShareTicketModalOpen(true)}
              className='flex items-center hover:underline hover:cursor-pointer hover:bg-gray-100 rounded-md p-2 -m-2'
            >
              <RiShare2Line className='w-6 h-6' />
              <span className='ml-2 tracking-tight font-semibold'>{m.order_ticket_share()}</span>
          </button>
          )}
          {!ticket.ticketMustBePersonalized && ticket.ticketStatus === TicketStatus.available && (
            <ShareTicketWithoutPersonalisationButton ticketId={ticket.ticketId} productName={ticket.productName} />
          )}
            {/* <div className='flex items-center'>
              <img src={Sold} alt="" className='w-5 h-5'/>
              <span className='ml-2 tracking-tight font-semibold'>Sell</span>
            </div> */}
          </div>
          
        </div>
      </div>
    </div>
  );
};

export { Ticket };