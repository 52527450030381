import React from 'react';
import { clsx } from 'clsx';
import { shopVar } from '@/lib/shopStyles';

interface MainButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  url?: string;
  newTab?: boolean;
}

export const MainButton: React.FC<MainButtonProps> = ({ disabled, onClick, children, className, url, newTab }) => {
  const commonProps = {
    className: clsx(
      'flex items-center justify-center rounded-md px-4 py-2 hover:cursor-pointer hover:opacity-80 group',
      {
        'opacity-40': disabled,
        'pointer-events-none': disabled,
      },
      className
    ),
    style: {
      backgroundColor: shopVar('--shop-ctaButtonColor'),
      color: shopVar('--shop-ctaButtonTextColor'),
    },
    role: "button",
    tabIndex: disabled ? -1 : 0,
  };

  if (url) {
    return (
      <a href={url} {...commonProps} target={newTab ? '_blank' : '_self'}>
        {children}
      </a>
    );
  }
  return <div {...commonProps} onClick={!disabled ? onClick : undefined}>{children}</div>;
};

export default MainButton;