import { RiShare2Line } from '@remixicon/react';
import { shareOnMobile } from "react-mobile-share";
import { useState } from 'react';
import copy from 'copy-to-clipboard';
import * as m from "@/paraglide/messages.js"
import { useEvent } from '@/providers/EventProvider';
import { useIsMobile } from '@/hooks/useIsMobile';

interface ShareTicketButtonProps {
  ticketId: string;
  productName: string;
}

export const ShareTicketWithoutPersonalisationButton: React.FC<ShareTicketButtonProps> = ({ ticketId, productName }) => {
  const [showCopied, setShowCopied] = useState(false);
  const { event } = useEvent();
  const isMobile = useIsMobile();

  const handleShare = () => {
    const url = `${window.location.origin}/shared-ticket/${ticketId}`;
    
    if (isMobile) {
      shareOnMobile({
        text: m.share_dialog_text({
          productName,
          eventName: event?.name ?? '',
        }),
        url,
        title: m.share_dialog_title({ eventName: event?.name ?? '' }),
        images: [],
      });
    } else {
      copy(url, {
        message: m.copied_to_clipboard(),
      });
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 3000);
    }
  };

  return (
    <div className='flex flex-col items-end'>
      <button
        onClick={handleShare}
        className='flex items-center hover:underline hover:cursor-pointer hover:bg-gray-100 rounded-md p-2 -m-2'
      >
        <RiShare2Line className='w-6 h-6' />
        <span className='ml-2 tracking-tight font-semibold'>{m.order_ticket_share()}</span>
      </button>
      {showCopied && <div className='pt-1 text-xs text-gray-500'>{m.url_copied_to_clipboard()}</div>}
    </div>
  );
}; 