import { displayEventDates } from "@/lib/utils";
import {LanguageSelect} from "../LanguageSelect";
import { shopVar } from "@/lib/shopStyles";
import { useEvent } from "@/providers/EventProvider";
import { useShopWhenAvailable } from "@/providers/ShopProvider";
import { RiCloseLine } from "@remixicon/react";
        
export const Header: React.FC = () => {
    const { event } = useEvent();

    const {isWidget, shopSlug} = useShopWhenAvailable() ?? {isWidget: false, shopSlug: ''};

    return (
        <header>
            <div className='p-4 bg-cover bg-center bg-no-repeat h-28 w-full md:rounded-3xl md:rounded-b-none' style={{backgroundImage: `url(${event.headerImage})`}}>
                {isWidget && <div className='md:hidden absolute top-6 right-6 p-2 hover:cursor-pointer hover:bg-gray-100/10 rounded-full hover:scale-105 transition-transform duration-200' onClick={() => {
                    window.dispatchEvent(new CustomEvent('shop-close', {detail: {shopSlug: shopSlug}}));
                }}>
                    <RiCloseLine size={30} color={'black'}/>
                </div>}
            </div>
            {/* <div className='p-4 bg-blue-500 h-14 w-full md:rounded-3xl md:rounded-b-none'/> */}
            <div className="px-8 py-4 flex items-center rounded-md rounded-t-none justify-between" style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}}>
                <div>
                    <div className='text-xl font-bold'>{event.name}</div>
                    <div>{displayEventDates(event, 'nl')}</div>
                </div>
                <LanguageSelect/>
            </div>
        </header>
    );
};