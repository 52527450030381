export type ShopStyleVariables = {
    '--shop-borderColor': string;
    '--shop-panelBackgroundColor': string;
    '--shop-textColor': string;
    '--shop-removeFromCartButtonColor': string;
    '--shop-removeFromCartButtonTextColor': string;
    '--shop-addToCartButtonColor': string;
    '--shop-addToCartButtonTextColor': string;
    '--shop-productErrorMessageBackground': string;
    '--shop-productErrorMessageText': string;
    '--shop-ctaButtonColor': string;
    '--shop-ctaButtonTextColor': string;
  };
  
  // Helper type to use in style objects
  export type ShopStyleVariableNames = keyof ShopStyleVariables;
  
  // Helper function to type-check variable names
  export function shopVar(name: ShopStyleVariableNames): string {
    return `var(${name})`;
  }