import { TicketShopContainer } from '@/TicketshopContainer'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/$slug/$event')({
  component: Event,
})

function Event() {
    const { slug, event } = Route.useParams()
    return <div className='bg-gradient-to-b from-white to-[#a7dbc7] sm:p-10 min-h-screen'>
            <div className='max-w-[800px] mx-auto bg-white rounded-xl pb-6 shadow-2xl'>
                <TicketShopContainer shopSlug={slug} eventSlug={event}/>
            </div>
        </div>;
}