import { Money } from "@/lib/money";
import {ServiceFee} from "./TicketApi";

export function displayFee(serviceFee?: ServiceFee, locale: string = 'en', prefix: string = '', postfix: string = '', noFeeText: string = '') : string {
    if (serviceFee === undefined) {
        return noFeeText;
    }
    
    if(serviceFee.amountPerUnit === undefined && serviceFee.percentage === undefined) {
        return noFeeText;
    }

    if(serviceFee.amountPerUnit === null && serviceFee.percentage === null) {
        return noFeeText;
    }
    

    if(serviceFee.amountPerUnit !== undefined && serviceFee.amountPerUnit !== null){
        if(serviceFee.amountPerUnit?.amount <= 0) {
            return noFeeText;
        }
        return prefix + Money.fromInteger(serviceFee.amountPerUnit.amount, serviceFee.amountPerUnit.currency).display(locale) + postfix;
    }

    return prefix + serviceFee.percentage + '%' + postfix;
}
