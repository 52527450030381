import { Product } from "@/lib/TicketApi";
import { format, parseISO } from "date-fns";
import { isFuture } from "date-fns";
import ProductAvailable from "./ProductAvailable";
import * as m from "@/paraglide/messages.js"
interface AvailableBetweenProps {
    product: Product;
}

export default function AvailableBetween({product}: AvailableBetweenProps) {
    if(product.availability.type !== "available_between") {
        return null;
    }

    const { availableFrom, availableUntil } = product.availability;
    const startDate = availableFrom ? parseISO(availableFrom) : null;
    const endDate = availableUntil ? parseISO(availableUntil) : null;

    if(endDate !== null && !isFuture(endDate)) {
        return <div className="flex flex-col justify-start opacity-60">
            {product.availability.showSoldOutOnEndDateTime ? 
                <div className="text-xl font-light py-1">{m.product_sold_out()}</div> : 
                <div className="text-xl font-light py-1">{m.product_not_available()}</div>}
        </div>;
    }

    if(startDate !== null && isFuture(startDate)) {
        return <div className="flex flex-col justify-start opacity-60">
            <div className="text-xl font-light py-1">{m.product_coming_soon()}</div>
            {product.availability.showStartDateTime && <div>{format(startDate, 'M/d/yyyy HH:mm')}</div>}
        </div>;
    }
    
    return <ProductAvailable product={product} />
}