import {FormPartComponent, OrderDetail} from "@/components/customer-details-form/FormParts/FormParts"
import React, {useEffect} from "react";
import {useForm} from "@mantine/form";
import emailSpellChecker from '@zootools/email-spell-checker';
import {MailSuggestion} from "@zootools/email-spell-checker/dist/lib/types";
import distance from '@zootools/email-spell-checker/dist/lib/helpers/sift3Distance'
import { shopVar } from "@/lib/shopStyles";
import * as m from "@/paraglide/messages.js"

export function OrderDetails({formPart, onValueChange, onValidStatusChange, answers} : FormPartComponent<OrderDetail>) {

    const [suggestions, setSuggestions] = React.useState<MailSuggestion|undefined>(undefined);
    const form = useForm({
        mode: 'controlled',
        initialValues: {
            // @ts-expect-error answers is object
            first_name: answers['first_name'] ?? '',
            // @ts-expect-error answers is object
            last_name: answers['last_name'] ?? '',
            // @ts-expect-error answers is object
            email: answers['email'] ?? '',
            // @ts-expect-error answers is object
            termsOfService: answers['termsOfService'] ?? false,
        },
        validateInputOnChange: [
            'email', 'first_name', 'last_name', 'termsOfService'
        ],
        validate: {
            first_name: (value: string) => (value.length > 0 ? null : m.first_name_is_required()),
            last_name: (value: string) => (value.length > 0 ? null : m.last_name_required()),
            email: (value: string) => (checkEmail(value) ? null : m.invalid_email_format()),
            termsOfService: (value: boolean) => (value ? null : m.must_agree_to_tos()),
        },
        onValuesChange: (values) => {
            onValueChange(formPart.type, values);
            onValidStatusChange(formPart.type, form.isValid());
        }
    });

    useEffect(() => {
        onValidStatusChange(formPart.type, form.isValid());
    }, []);

    const checkEmail = (email: string): boolean => {
        setSuggestions(emailSpellChecker.run({
            email: email,
            distanceFunction: (domain: string, knownDomain: string) => {
                let dist = distance(domain, knownDomain)
                // force prioritize .com matches over .co and .ca
                if (knownDomain === 'com') dist -= 0.75
                return dist
            },
        }));

        // Import Mailcheck here

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
    <div className="grid grid-cols-1 gap-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-4">
            <div className="col-span-1">
                <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
                    {/* {formPart.first_name.label} */}
                    {m.form_first_name()}
                </label>
                <div className="mt-2">
                    <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        placeholder="John"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        {...form.getInputProps('first_name')}
                    />
                {form.errors.first_name && <p id="first_name-error" className="mt-2 text-sm text-red-600">{form.errors.first_name}</p>}
                </div>
            </div>
            <div className="col-span-1">
                <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                    {/* {formPart.last_name.label} */}
                    {m.form_last_name()}
                </label>
                <div className="mt-2">
                    <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        placeholder="Doe"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        {...form.getInputProps('last_name')}
                    />
                    {form.errors.last_name && <p id="last_name-error" className="mt-2 text-sm text-red-600">{form.errors.last_name}</p>}
                </div>
            </div>
        </div>
        <div className="">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                {/* {formPart.email.label} */}
                {m.form_email_address()}
            </label>
            <div className="mt-2">
                <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="you@example.com"
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...form.getInputProps('email')}
                />
             {suggestions && <div className="font-bold mt-2 text-sm">{m.email_typo_suggestion()}<a onClick={() => form.setFieldValue('email', suggestions.full)} href="#">{suggestions.full}</a>?</div>}
             {form.errors.email && <p id="email-error" className="mt-2 text-sm text-red-600">{form.errors.email}</p>}
            </div>
        </div>
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="terms"
              name="terms"
              type="checkbox"
              aria-describedby="terms-description"
              className="h-4 w-4 rounded border-gray-300 focus:ring-indigo-600"
              style={{
                color: shopVar('--shop-textColor'),
              }}
              {...form.getInputProps('termsOfService', {type: 'checkbox'})}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="comments" className="font-medium text-gray-900">
                {m.agree_to_terms_pt1()}<a href="https://www.fastlane.events/fastlane-terms-conditions/fastlane-terms-and-conditions-for-ticketing-services" className="font-bold" target="_blank">Fastlane ticketing</a> {m.agree_to_terms_pt2()} <a href={formPart.terms.termsAndConditionsUrl} className="font-bold" target="_blank">{formPart.terms.companyName}</a>
            </label>
          </div>
        </div>
        {form.errors.termsOfService && <p id="termsOfService-error" className="text-sm text-red-600">{form.errors.termsOfService}</p>}
      </div>
    );
}
