
import React from 'react';
import { Header } from '@/components/header/Header';
import { EventProvider } from '@/providers/EventProvider';
import { Event, ShopStyle } from '@/lib/TicketApi';
import { StyleOverwrites } from '@/providers/StyleOverwrites';

interface OrderPageShellProps {
  event: Event;
  children: React.ReactNode;
  shopStyle?: ShopStyle;
}

const OrderPageShell: React.FC<OrderPageShellProps> = ({ event, children, shopStyle }) => {
  return (
    <StyleOverwrites shopStyle={shopStyle}>
      <div className='bg-gradient-to-b from-[#9bb7ff] to-[#a7dbc7] sm:p-10 min-h-screen font-sans'>
        <EventProvider event={event}>
          <div className='max-w-[640px] mx-auto'>
            <div className='bg-white rounded-3xl rounded-bl-md rounded-br-md'>
              <Header />
            </div>
          {children}
          </div>
        </EventProvider>
      </div>
      </StyleOverwrites>
  );
};

export default OrderPageShell;
