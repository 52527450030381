import { Ticket } from '@/components/order/Ticket';
import { OrderApi } from '@/lib/OrderApi'
import type { Order } from '@/lib/OrderApi'
import { Await, createFileRoute, defer, useRouter } from '@tanstack/react-router'
import OrderPageShell from '@/components/order/OrderPageShell';

// Loading skeleton component for tickets
const TicketSkeleton = () => (
  <div className="animate-pulse bg-white rounded-xl p-4 mb-4">
    <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
    <div className="space-y-3">
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
      <div className="h-4 bg-gray-200 rounded w-1/3"></div>
    </div>
  </div>
);

// Loading state component
const LoadingState = () => (
  <div className='bg-gradient-to-b from-[#9bb7ff] to-[#a7dbc7] sm:p-10 min-h-screen font-sans'>
    <div className='max-w-[640px] mx-auto'>
      <div className='bg-white rounded-md rounded-bl-md rounded-br-md'>
        <div className='text-center text-lg font-bold py-4'>
          Loading order...
        </div>
      </div>
      <div className='px-4 sm:px-0 pb-40 space-y-4 mt-4'>
        <TicketSkeleton />
        <TicketSkeleton />
        <TicketSkeleton />
      </div>
    </div>
  </div>
);

const OrderNotFound = () => (
  <div className='bg-gradient-to-b from-[#9bb7ff] to-[#a7dbc7] sm:p-10 min-h-screen font-sans'>
    <div className='max-w-[640px] mx-auto'>
      <div className='bg-white rounded-md rounded-bl-md rounded-br-md'>
        <div className='text-center text-lg font-bold pt-4'>Order not found</div>
        <div className='text-center text-sm py-4 px-4'>
          We couldn't find the order you are looking for. 
          <br /><br />
          <p className='font-bold'>If you just purchased tickets, it could be that our systems are busy. Please wait a few minutes and try again. You should receive an email with your tickets shortly.</p>
          <br />
          If you believe this is an error, please contact our support. <a href="mailto:ticketing@fastlane.events">ticketing@fastlane.events</a>
        </div>
      </div>
    </div>
  </div>
)

export const Route = createFileRoute('/order/$orderId')({
  loader: async ({ params }: { params: { orderId: string } }) => {
    const loadOrder = async () => {
      const ticketApi = new OrderApi(import.meta.env.VITE_API_URL);
      let order;
      let attempts = 0;
      const maxAttempts = 30;
      const interval = 1000; // 1 second

    while (attempts < maxAttempts) {
      try {
        order = await ticketApi.getOrder(params.orderId);
        break; // If successful, exit the loop
      } catch (error) {
        attempts++;
          if (attempts >= maxAttempts) {
            console.error(error);
            // throw error; // If all attempts fail, throw the last error
            return null;
          }
          await new Promise(resolve => setTimeout(resolve, interval));
        }
      }

      return order;
    }

    return { order: defer(loadOrder()) }
  },
  component: Order,
})

// const getWarnings = (order: Order) => {
//   let warnings = [];
//   if (order.tickets.some(ticket => ticket.ticketStatus === TicketStatus.claimable)) {
//     warnings.push(<div>You have claimable tickets in your order. Please claim them before you can resell them.</div>);
//   }
//   return warnings;
// }


function Order() {
  const { order } = Route.useLoaderData()

  const router = useRouter();
  const reloadOrder = async () => {
    await router.invalidate() // This will cause the loader to re-run
  }

  return (
    <Await promise={order} fallback={<LoadingState />}>
      {(data) => {
        if (!data || data == null) return <OrderNotFound />;
        return (
          <OrderPageShell event={data.event}>
         {/* <div className='border-dashed border-b border-black mx-4'></div> */}
                {/* <ImportInfo className='px-8 py-4'> */}
                  {/* <ImportInfo.Title>Import Information</ImportInfo.Title> */}
                  {/* <ImportInfo.Item>Your personal ticket <b>needs to be activated</b> as mobile ticket</ImportInfo.Item> */}
                  {/* <ImportInfo.Item>Tickets for your group need to be <b>shared with them</b></ImportInfo.Item> */}
                  {/* <ImportInfo.Item>You can only resell tickets <b>via our marketplace</b> (max +20%)</ImportInfo.Item> */}
                {/* </ImportInfo> */}
              <div className='px-4 sm:px-0 pb-40'>
                {data.tickets.map((ticket) => (
                  <Ticket orderId={data.orderId} ticket={ticket} key={ticket.ticketId} answers={data.formAnswers} triggeredReload={reloadOrder} marketplaceUrl={data.marketplaceUrl}/>
                ))}
              </div>
            
          </OrderPageShell>
        );
      }}
    </Await>
  )
}
