import React from 'react';
import { shopVar } from '@/lib/shopStyles';
import clsx from 'clsx';

interface PanelProps {
  children: React.ReactNode;
  className?: string;
}

export const Panel: React.FC<PanelProps> = ({children, className}) => {
  return (
    <div className={clsx('mt-4 px-8 py-4 rounded-md', className)} style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}}>
        {children}
    </div>
  );
};