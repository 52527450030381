import { createFileRoute } from '@tanstack/react-router'

import { Panel } from '@/components/shared/Panel';
import { MainButton } from '@/components/shared/MainButton';
import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react';
import { displayFee } from '@/lib/displayFee';
import { useEffect, useState } from 'react';
import { CustomerDetailsForm } from '@/components/customer-details-form/CustomerDetailsForm';

import { useMarketplaceCart } from '@/providers/MarketplaceCartProvider';
import VerifiedSeller from '../../../../assets/verifiedseller.svg';
import { Money } from '@/lib/money';
import { useLanguage } from '@/providers/LanguageProvider';
import QuantitySelectButton from '@/components/QuantitySelectButton';
import { clsx } from 'clsx';
import { shopVar } from '@/lib/shopStyles';
import OrderPageShell from '@/components/order/OrderPageShell';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/shared/Accordion';
import { CartItems } from '@/components/marketplace/CartItems';
import { WaitlistSignup } from '@/components/marketplace/WaitlistSignup';

export const Route = createFileRoute('/marketplace/$slug/')({
  validateSearch: (search: Record<string, unknown>): {listing: string|undefined} => {
    return {
      listing: search.listing as string|undefined
    }
  },
  component: MarketplaceContent,
})

function MarketplaceContent() {

  const { currentLanguage: lang } = useLanguage();
  const { cart, updateQuantity, isLoading, cartTotal, getCartItemTotal, marketplace, formState, setFormState, formValid, setFormValid, refreshCart } = useMarketplaceCart();
  const [step, setStep] = useState<'cart' | 'form'>("cart");

  const { listing: filteredListingId } = Route.useSearch();

  useEffect(() => {
    if(cart.orderId !== null) {
      refreshCart();
    }
  }, [cart.orderId]);


  const navigate = Route.useNavigate();

  return <OrderPageShell event={marketplace.event} shopStyle={marketplace.style}>
      {step === 'cart' && (
          <>
              <div className='bg-white px-8 pt-8 pb-4 -mt-4 rounded-b-md'>
                <div>
                    <div className='text-lg font-bold'>Resale marketplace</div>
                    <div className='text-xs pt-2'>
                        {marketplace.forSaleCount} for sale • {marketplace.soldCount} sold • {marketplace.waitlistCount} wanted on waitlist
                    </div>
                    <div className='text-xs'>
                        Buying a ticket here 100% guarantees entrance
                    </div>
                </div>
                <WaitlistSignup slug={marketplace.slug} />
                <div>
              {Object.entries(marketplace.listings.filter(l => filteredListingId === undefined || l.id === filteredListingId).reduce((acc, listing) => {
                  if (!acc[listing.product_id]) {
                      acc[listing.product_id] = [];
                  }
                  acc[listing.product_id].push(listing);
                  return acc;
              }, {} as Record<string, typeof marketplace.listings>))
              .map(([productId, listings]) => (
                  <Accordion 
                      key={productId}
                      className={clsx('py-2rounded-md justify-between items-center')}
                      style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}}
                      type="single"
                      defaultValue={filteredListingId ? 'opened' : 'closed'}
                      collapsible
                          >
                              <AccordionItem value="opened">
                                  <AccordionTrigger canCollapse={true}>
                                      <div className="flex w-full flex-col sm:flex-row items-start sm:items-center justify-between">
                                          <div className="text-xl font-bold leading-normal">{listings[0].product_name}</div>
                                          <div className='sm:ml-4 text-sm'>{listings.flatMap(l => l.available).reduce((a, b) => a + b, 0)} tickets, lowest price {Money.fromInteger(Math.min(...listings.flatMap(l => l.price.amount)), listings[0].price.currency).display(lang)}</div>
                                      </div>
                                  </AccordionTrigger>
                                  <AccordionContent>
                                      {listings
                                      .sort((a, b) => a.price.amount - b.price.amount)
                                      .map(listing => (
                                          <div key={listing.id} className='bg-gray-50 p-4 mb-4 last:mb-0 rounded-md shadow-md flex justify-between items-center gap-2'>
                                              <div className='flex items-center gap-2'>
                                                  <img src={VerifiedSeller} alt="Verified seller" className='mt-1 w-8 h-8 mx-2 hidden sm:block' />
                                                  <div>
                                                      <div className='font-semibold mt-1'>{Math.max(listing.available, cart.reserved.find(item => item.listingId === listing.id)?.quantity || 0)} tickets</div>
                                                      <div className='text-xs tracking-tight mt-1'>Verified seller</div>
                                                  </div>
                                                  <div className='text-left sm:pl-2 md:pl-10'>
                                                      <div className='text-xl font-bold'>{Money.fromInteger(listing.price.amount, listing.price.currency).display(lang)}</div>
                                                      <div className='text-xs tracking-tight'>{displayFee(listing.fee, lang, '+ ', " " + listing.fee.name)}</div>
                                                  </div>
                                              </div>
                                              
                                              <QuantitySelectButton
                                                  quantity={cart.reserved.find(item => item.listingId === listing.id)?.quantity || 0}
                                                  limit={listing.available}
                                                  loading={isLoading[listing.id] || false}
                                                  onIncrease={() => updateQuantity(listing.id, (cart.reserved.find(item => item.listingId === listing.id)?.quantity || 0) + 1)}
                                                  onDecrease={() => updateQuantity(listing.id, (cart.reserved.find(item => item.listingId === listing.id)?.quantity || 0) - 1)}
                                              />
                                          </div>
                                      ))}
                                  </AccordionContent>
                              </AccordionItem>
                          </Accordion>
              ))}
          </div>
              </div>
              <Panel>
                  <CartItems reserved={cart.reserved} listings={marketplace.listings} getCartItemTotal={getCartItemTotal}/>
                  <div className='flex justify-end'>
                      <div className='text-xl font-bold'>Total: </div>
                      <div className='text-xl font-bold leading-7 ml-2'>{cartTotal.display(lang)}</div>
                  </div>
                  <div className='py-2'>
                      <MainButton disabled={(cart.reserved.flatMap(item => item.quantity).reduce((a, b) => a + b, 0) === 0) || Object.keys(isLoading).flatMap(key => isLoading[key]).reduce((a, b) => a || b, false)} onClick={() => {setStep('form')}}>
                          <div className='text-xl font-bold leading-7'>Next</div>
                          <RiArrowRightSLine className="h-8 w-8 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                      </MainButton>
                  </div>
              </Panel>
          </>
      )}
      {step === 'form' && (
          <Panel>
              <CustomerDetailsForm
                      form={marketplace.form}
                      answers={formState}
                      errors={[]}
                      setAnswers={setFormState}
                          setFormValid={setFormValid}
                      />
                  <div className='flex gap-2 mt-4'>
                      <div className='border-2 border-black rounded-md p-2 hover:cursor-pointer hover:opacity-80 hover:bg-gray-100' onClick={async () => {
                          setStep('cart');
                      }}>
                          <RiArrowLeftSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                      </div>
                      <MainButton disabled={!formValid} 
                          className='flex-grow'
                          onClick={async () => {
                              navigate({ to: '/marketplace/$slug/checkout/$cart', params: { cart: cart.cartId } });
                          }}>
                          <div className='text-lg font-bold leading-7'>Next</div>
                          <RiArrowRightSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                      </MainButton>
                  </div>
          </Panel>
      )}
  </OrderPageShell>
}

