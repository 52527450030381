import React from 'react';
import { useShop } from '../providers/ShopProvider';
import * as m from "@/paraglide/messages.js"
export const OrganisationLegalInformation: React.FC = () => {
    const { shop } = useShop();
    const { organisationLegalInformation } = shop;

    return (
        <div className="font-normal text-xs py-4 text-center tracking-tight" 
        dangerouslySetInnerHTML={{__html: m.legal_text({
            companyName: organisationLegalInformation.companyName,
            chamberOfCommerceNumber: organisationLegalInformation.chamberOfCommerceNumber,
            vatNumber: organisationLegalInformation.vatNumber,
            addressLine: organisationLegalInformation.addressLine,
            termsAndConditionsUrl: organisationLegalInformation.termsAndConditionsUrl,
            contactEmail: organisationLegalInformation.contactEmail,
            contactPhone: organisationLegalInformation.contactPhone
        })}}>
            
        </div>
    );
};
