import {FunctionComponent, useEffect, useState} from "react";
import { FormPartComponent, FormPartType } from "./FormParts/FormParts";
import { OrderDetails } from "./FormParts/OrderDetails";
import { OrderedList } from "@/lib/TicketApi";
import { FormComponent } from "@/lib/TicketApi";

interface CustomerDetailsFormProps {
    form: OrderedList<FormComponent>;
    answers: object;
    errors: string[]|string[][];
    setAnswers: (answers: object) => void;
    setFormValid: (valid: boolean) => void;
}

export function CustomerDetailsForm({form, answers, errors, setAnswers, setFormValid}: CustomerDetailsFormProps) {

    const [formPartValidStatus, setFormPartValidStatus] = useState({})

    const formPartComponents: {[k in FormPartType]: FunctionComponent<FormPartComponent<any>>} = {
        [FormPartType.OrderDetail]: OrderDetails,
    }

    const allFormPartsAreValid = Object.keys(formPartValidStatus).every((key) => {
        return formPartValidStatus[key as keyof typeof formPartValidStatus];
    });

    useEffect(() => {
        setFormValid(allFormPartsAreValid);
    }, [allFormPartsAreValid, setFormValid]);

    
    return (
        <>
            {Object.keys(form)
                .map((key) => {
                    // @ts-expect-error key is string
                    const formPart = form[key];
                    // @ts-expect-error formPart.type is string
                        const Component = formPartComponents[formPart.type];
                        return (
                            <Component key={key}
                                       formPart={formPart}
                                       // @ts-expect-error answers is object
                                       answers={answers[formPart.type] ?? {}}
                                       errors={errors[formPart.type] ?? []}
                                       onValidStatusChange={(formPartType: string, value: boolean) => setFormPartValidStatus({
                                ...formPartValidStatus,
                                [formPartType]: value
                            })}
                                       onValueChange={(formPartType: string, value: string|object) => {
                                           setAnswers({
                                               ...answers,
                                               [formPartType]: value
                                           });
                                       }}/>
                        )
                    }
                )}
        </>
    )
}
