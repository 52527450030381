// your-dialog.jsx
import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
import clsx from "clsx";

export const DialogContent = React.forwardRef<
	HTMLDivElement,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ children, ...props }, forwardedRef) => (
	<DialogPrimitive.Portal>
		<DialogPrimitive.Overlay className="fixed inset-0 bg-black/80 backdrop-blur-sm data-[state=open]:animate-overlayShow" />
		<DialogPrimitive.Content {...props} ref={forwardedRef} className="px-4 md:px-8 py-4 fixed left-1/2 top-1/2 max-h-[85vh] w-[90vw] max-w-[450px] -translate-x-1/2 -translate-y-1/2 rounded-md bg-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow">
			{children}
			<DialogPrimitive.Close aria-label="Close" className="absolute top-4 right-4">
					<Cross1Icon className="w-6 h-6 text-gray-500 hover:text-gray-700 hover:scale-110 transition-all duration-300" />
				</DialogPrimitive.Close>
			</DialogPrimitive.Content>
		</DialogPrimitive.Portal>
	),
);

export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;

export const DialogTitle = React.forwardRef<
  HTMLHeadingElement,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, forwardedRef) => (
  <DialogPrimitive.Title
    ref={forwardedRef}
    className={clsx('text-lg font-semibold mb-2', className)}
    {...props}
      />
    )
  );

  
  
export const DialogDescription = DialogPrimitive.Description;

export const DialogClose = DialogPrimitive.Close;