import React from 'react';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/shared/Accordion';
import { ProductSection as PsType } from '@/lib/TicketApi';
import { Product } from './Product';
import { shopVar } from '@/lib/shopStyles';
import clsx from 'clsx';
import { useShop } from '@/providers/ShopProvider';

interface ProductSectionProps {
    productSection: PsType;
}

const ProductSection: React.FC<ProductSectionProps> = ({productSection}) => {
    const {isWidget} = useShop();

    if(productSection.products.length === 0) {
        return null;
    }

    return <Accordion
            className={clsx('py-2 px-8 rounded-md justify-between items-center', isWidget ? 'mt-4' : '')}
            style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}}
            type="single"
            defaultValue={productSection.openedDefault ? 'opened' : 'closed'}
            collapsible
        >
            <AccordionItem value="opened">
                <AccordionTrigger canCollapse={productSection.canCollapse}>
                    <div className="justify-center items-center flex">
                        <div className="text-xl font-bold leading-normal">{productSection.name}</div>
                    </div>
                </AccordionTrigger>
                <AccordionContent>
                    {productSection.products.map((product) => (
                        <Product key={product.productId} product={product}/>
                    ))}
                </AccordionContent>
            </AccordionItem>
        </Accordion>
};

export default ProductSection;