import { Field, Label, Radio, RadioGroup } from '@headlessui/react';
import { displayFee } from '@/lib/displayFee';
import { shopVar } from '@/lib/shopStyles';
import { useLanguage } from '@/providers/LanguageProvider';
import * as m from "@/paraglide/messages.js"
import { ServiceFee } from '@/lib/TicketApi';

type PaymentMethod = {
    id: string;
    name: string;
    image: string;
    fee: ServiceFee; // Replace with proper fee type
}

interface SelectPaymentMethodProps {
    paymentMethods: PaymentMethod[];
    selectedMethod: string|null;
    onMethodChange: (method: string) => void;
    className?: string;
}

export function SelectPaymentMethod({ paymentMethods, selectedMethod, onMethodChange, className }: SelectPaymentMethodProps) {
    const { currentLanguage: lang } = useLanguage();

    return (
        <RadioGroup value={selectedMethod} onChange={onMethodChange} aria-label="Payment method" className={`flex flex-col gap-4 ${className}`}>
            {paymentMethods.map((method) => (
                <Field key={method.id} className="flex items-center gap-6 w-full hover:cursor-pointer">
                    <Radio
                        value={method.id}
                        className="group flex size-6 items-center justify-center rounded-full border"
                        style={{
                            borderColor: shopVar('--shop-textColor'),
                            ...(selectedMethod === method.id ? {
                                backgroundColor: shopVar('--shop-textColor'),
                            } : {
                                backgroundColor: shopVar('--shop-panelBackgroundColor'),
                            })
                        }}
                    >
                        <span className="invisible size-2.5 rounded-full group-data-[checked]:visible" style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}} />    
                    </Radio>
                    <Label className='hover:cursor-pointer flex items-center gap-4'>
                        <img src={method.image} alt={method.name} className="w-10"/>
                        <div className="flex flex-col justify-between">
                            <span className='font-semibold'>{method.name}</span>
                            <div className="text-sm tracking-tight">{displayFee(method.fee, lang, '', ' ', m.payment_method_free())}</div>
                        </div>
                    </Label>
                </Field>
            ))}
        </RadioGroup>
    );
} 