import {useEffect, useRef} from "react";
import { useCart } from '@/providers/CartProvider';
import { Panel } from "@/components/shared/Panel";
import { CustomerDetailsForm } from "@/components/customer-details-form/CustomerDetailsForm";

export function Form() {

    const {cart, setFormValid, formState, setFormState, loading} = useCart();
    const initializedRef = useRef(false);

    useEffect(() => {
        if(cart === undefined || cart === null || loading) {
            return;
        }

        if (!initializedRef.current) {
            setFormState(cart.answers ?? {});
            initializedRef.current = true;
        }
    }, [cart, setFormState, loading]);

    if(cart === undefined || cart === null || formState === null) {
        return <></>;
    }

    return (
        <Panel>
            <CustomerDetailsForm 
                form={cart.form} 
                answers={formState} 
                errors={cart.errors} 
                setAnswers={setFormState} 
                setFormValid={setFormValid}
            />
        </Panel>
    );
}