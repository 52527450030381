'use client';

import { Product } from "@/lib/TicketApi";
import { useLanguage } from '@/providers/LanguageProvider';
import { useCart } from "@/providers/CartProvider";
import QuantitySelectButton from "@/components/QuantitySelectButton";

interface ProductCartButtonProps {
    product: Product;
}

export default function ProductAvailable({product}: ProductCartButtonProps) {
    const {setProductQuantity, selectedQuantity, loading, totalForProduct, productError, cart} = useCart();

    const { currentLanguage: lang } = useLanguage();

    let limit = product.maxQuantity;
    if(product.isHidden){
        if(cart === null || cart.appliedCouponCode === null || cart.appliedCouponCode.unlockProducts === null){
            limit = 0;
        } else {
            limit = cart.appliedCouponCode.unlockProducts.products.find(p => p.productId === product.productId)?.quantity ?? 0;
        }
    }
    // console.log(product.);

    const handleDecrease = () => {
        const currentQuantity = selectedQuantity(product.productId);
        if (currentQuantity > 0) {
            setProductQuantity(product.productId, currentQuantity - 1);
        }
    };

    const handleIncrease = () => {
        const currentQuantity = selectedQuantity(product.productId);
        if(currentQuantity >= limit){
            return;
        }
        setProductQuantity(product.productId, currentQuantity + 1);
    };

    const forProduct = totalForProduct(product.productId);

    return (
        <>
        <div className="flex justify-between py-2">
            <div className="text-base font-bold">
                {forProduct !== null ? forProduct.totalInclFee.display(lang) : ''}
            </div>
            <QuantitySelectButton
                quantity={selectedQuantity(product.productId)}
                limit={limit}
                loading={loading}
                onIncrease={handleIncrease}
                onDecrease={handleDecrease}
            />
        </div>
            
            {productError(product.productId) && 
            <div className="font-bold text-red-500 mt-2 -ml-2 p-2 bg-red-100 rounded-md">
                {productError(product.productId)}
            </div>}
        </>
    )
}